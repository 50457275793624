import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Wrapper from 'components/Wrapper';
import CloseIcon from '@material-ui/icons/Close';
import { LineSpaceBetween, TitleText, styles } from './styles';

type propTypes = Partial<{
  classes: any;
  title: any;
  onClose: any;
  children: any;
  OnCloseButton: any;
  DialogAction: any;
  maxWidth: any;
  open: boolean;
  Icon?: React.ReactNode;
  noPadding: boolean;
  fullScreen: boolean;
  fullWidth?: boolean;
  disableScrollLock?: boolean;
}>;

const GenericDialog: React.FC<propTypes> = ({
  classes,
  title,
  Icon,
  onClose,
  children,
  OnCloseButton = true,
  DialogAction,
  maxWidth = 'md',
  fullWidth = true,
  open = true,
  noPadding = false,
  fullScreen = false,
  disableScrollLock = false,
}) => {
  return (
    <Dialog
      open={open}
      aria-labelledby={title}
      onClose={onClose}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      fullScreen={fullScreen}
      disableScrollLock={disableScrollLock}
    >
      {title && OnCloseButton && (
        <DialogTitle>
          <LineSpaceBetween>
            <Wrapper flex align="center" gap="sm">
              {Icon}
              <TitleText>{title}</TitleText>
            </Wrapper>
            {OnCloseButton && (
              <CloseIcon onClick={onClose} style={{ cursor: 'pointer' }} />
            )}
          </LineSpaceBetween>
        </DialogTitle>
      )}
      <DialogContent className={noPadding ? classes.zeroPadding : classes.root}>
        {children}
      </DialogContent>
      {DialogAction ? (
        <DialogActions className={classes.rootActions}>
          <DialogAction />
        </DialogActions>
      ) : (
        <div style={{ minHeight: '16px' }}></div>
      )}
    </Dialog>
  );
};

export default withStyles(styles)(GenericDialog);
