import request from 'helpers/request';
import EnvVars from 'helpers/envVars';
import buildHTTPResponseHandler from 'helpers/buildHTTPResponseHandler';
import { buildQueryParams } from 'helpers/buildQueryParams';

const serviceName = 'InventoryService';
const domain = EnvVars('API_PAINEL_DIGITAL_BASE_URL');
const domainSearch = EnvVars('API_SEARCH_BASE_URL');

export default class InventoryService {
  static getVehicleByPlate = (dealerId, plate) => {
    return request
      .post(`${domain}/vehicle/${dealerId}/v1.0/plate/${plate}`, {})
      .then(buildHTTPResponseHandler(`${serviceName}.getVehicleByPlate()`));
  };

  static getInventorySituation = (dealerId, plateOrVin) => {
    return request
      .get(`${domain}/dealer/${dealerId}/inventory/v1.0/deal/situation`, {
        query: plateOrVin,
      })
      .then(buildHTTPResponseHandler(`${serviceName}.getInventorySituation()`));
  };

  static createInventory = (dealerId, obj) => {
    return request
      .post(`${domain}/dealer/${dealerId}/inventory/v1.0`, {
        ...obj,
        price: Number(obj.price),
        km: Number(obj.km),
      })
      .then(buildHTTPResponseHandler(`${serviceName}.createInventory()`));
  };

  static getInventoryById = (dealerId, dealId) => {
    return request
      .get(`${domain}/dealer/${dealerId}/inventory/v1.0/${dealId}`)
      .then(buildHTTPResponseHandler(`${serviceName}.getInventoryById()`));
  };

  static getVehicleImagesByTrim = (dealerId, trimId) => {
    return request
      .get(`${domain}/dealer/${dealerId}/inventory/v1.0/vehicleimages`, {
        trimId,
      })
      .then(
        buildHTTPResponseHandler(`${serviceName}.getVehicleImagesByTrim()`)
      );
  };

  static getDealByTrim = trimId => {
    return request
      .get(`${domainSearch}/vehicle/v1.0/trim/${trimId}`)
      .then(buildHTTPResponseHandler(`${serviceName}.getDealByTrim()`));
  };

  static updateInventoryById = (dealerId, dealId, data) => {
    return request
      .put(`${domain}/dealer/${dealerId}/inventory/v1.0/${dealId}`, data)
      .then(buildHTTPResponseHandler(`${serviceName}.updateInventoryById()`));
  };

  static deleteInventory = (dealerId, dealId) => {
    return request
      .delete(`${domain}/dealer/${dealerId}/inventory/v1.0/${dealId}`)
      .then(buildHTTPResponseHandler(`${serviceName}.deleteInventory()`));
  };

  static getDeals = (dealerId, page, size, options) => {
    const url = `${domain}/dealer/${dealerId}/inventory/v1.1/pagination`;
    const params = { page, size, ...options };
    return request
      .get(url, params)
      .then(buildHTTPResponseHandler(`${serviceName}.getDeals()`));
  };

  static getDealsStock = ({ dealerId, order, page, size, options }) => {
    const url = `${domain}/dealer/${dealerId}/inventory/v2.0/listing?order=${order}&size=${size}&page=${page}`;
    return request
      .post(url, options)
      .then(buildHTTPResponseHandler(`${serviceName}.getDealsStock()`));
  };

  static getDealsStockMechanicalWarranty = ({
    dealerId,
    order,
    page,
    size,
    options,
  }) => {
    const url = `${domain}/dealer/${dealerId}/inventory/v3.0/listing?order=${order}&size=${size}&page=${page}`;
    return request
      .put(url, options)
      .then(buildHTTPResponseHandler(`${serviceName}.getDealsStock()`));
  };

  static getStock = (dealerId, isZero = true, isUsado = true) => {
    return request
      .get(`${domain}/dealer/${dealerId}/inventory/v1.0/stockinfos`, {
        deal0km: isZero,
        dealUsado: isUsado,
      })
      .then(buildHTTPResponseHandler(`${serviceName}.getStock()`));
  };

  static getInfos = (dealerId, isZero = true, isUsado = true) => {
    return request
      .get(`${domain}/dealer/${dealerId}/inventory/v1.0/dashinfos`, {
        deal0km: isZero,
        dealUsado: isUsado,
      })
      .then(buildHTTPResponseHandler(`${serviceName}.getInfos()`));
  };

  static getPrice = (dealerId, state, trim, year) => {
    return request
      .get(`${domain}/price/${dealerId}/v1.0/price/${state}/${trim}/${year}`)
      .then(buildHTTPResponseHandler(`${serviceName}.getPrice()`));
  };

  static updatePriceByDealId = (dealerId, dealId, price) => {
    return request
      .put(
        `${domain}/dealer/${dealerId}/inventory/v1.0/${dealId}/price/${price}`,
        {}
      )
      .then(buildHTTPResponseHandler(`${serviceName}.updatePriceByDealId()`));
  };

  static getInfosDeal = (dealerId, dealId) => {
    return request
      .get(`${domain}/dealer/${dealerId}/inventory/v1.0/${dealId}/infos`)
      .then(buildHTTPResponseHandler(`${serviceName}.getInfosDeal()`));
  };

  static getPlans = (
    dealerId,
    trimId,
    deal0km = null,
    modelYear = null,
    dealId = null
  ) => {
    let params = {};
    if (deal0km !== null) {
      params = { deal0km, dealId };
    }
    return request
      .get(
        `${domain}/dealer/${dealerId}/inventory/v1.3/plans/${trimId}/model/${modelYear}`,
        params
      )
      .then(buildHTTPResponseHandler(`${serviceName}.getPlans()`));
  };

  static getPriorities = dealerId => {
    return request
      .get(`${domain}/dealer/${dealerId}/inventory/v1.3/plans/`)
      .then(buildHTTPResponseHandler(`${serviceName}.getPriorities()`));
  };

  static getConfigPortais = dealerId => {
    return request
      .get(`${domain}/dealer/${dealerId}/inventory/v1.0/providers`)
      .then(buildHTTPResponseHandler(`${serviceName}.getConfigPortais()`));
  };

  static syncPlansProvider = (dealerId, providerId) => {
    return request
      .put(
        `${domain}/integration/plan/dealer/${dealerId}/sync/${providerId}`,
        {}
      )
      .then(buildHTTPResponseHandler(`${serviceName}.syncPlansProvider()`));
  };

  static updateDealPublishById = (dealerId, dealId, publishId, data) => {
    return request
      .put(
        `${domain}/publishes/v1.0/update/${dealerId}/${dealId}/${publishId}`,
        data
      )
      .then(buildHTTPResponseHandler(`${serviceName}.updateDealPublishById()`));
  };

  static updateOneOrMoreDealPublish = (dealerId, dealId, data) => {
    return request
      .put(`${domain}/publishes/v1.0/update/${dealerId}/${dealId}`, data)
      .then(
        buildHTTPResponseHandler(`${serviceName}.updateOneOrMoreDealPublish()`)
      );
  };

  static getValueMinAndMax = (dealerId, trimId, modelYear) => {
    return request
      .get(`${domain}/price/${dealerId}/v1.0/${trimId}/${modelYear}`)
      .then(buildHTTPResponseHandler(`${serviceName}.getValueMinAndMax()`));
  };

  static publishingSelectedBatch = (dealerId, payload) => {
    return request
      .post(
        `${domain}/publishes/v2.0/dealer/${dealerId}/publish/selected`,
        payload
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.publishingSelectedBatch()`)
      );
  };

  static unPublishingSelectedBatch = (dealerId, providerNameApi, deals) => {
    return request
      .delete(
        `${domain}/integration/unpublish/dealer/${dealerId}/provider/${providerNameApi}`,
        {},
        { data: deals }
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.unPublishingSelectedBatch()`)
      );
  };

  static publishingBatchAll = (dealerId, providerNameApi) => {
    return request
      .post(
        `${domain}/integration/publish/dealer/${dealerId}/provider/${providerNameApi}/all`,
        {}
      )
      .then(buildHTTPResponseHandler(`${serviceName}.publishingBatchAll()`));
  };

  static unPublishingBatchAll = (dealerId, providerNameApi) => {
    return request
      .delete(
        `${domain}/integration/unpublish/dealer/${dealerId}/provider/${providerNameApi}/all`,
        {}
      )
      .then(buildHTTPResponseHandler(`${serviceName}.unPublishingBatchAll()`));
  };

  static deleteSelectedElements = (dealerId, deals) => {
    return request
      .delete(
        `${domain}/dealer/${dealerId}/inventory/v1.1`,
        {},
        { data: deals }
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.deleteSelectedElements()`)
      );
  };

  static downloadDetailsPaper = (dealerId, dealId) => {
    return request
      .get(
        `${domain}/dealer/${dealerId}/inventory/v1.0/${dealId}/paper`,
        {},
        { responseType: 'blob' }
      )
      .then(buildHTTPResponseHandler(`${serviceName}.downloadDetailsPaper()`));
  };

  static downloadDetailsBase64Paper = (dealerId, dealId) => {
    return request
      .get(`${domain}/dealer/${dealerId}/inventory/v1.1/${dealId}/paper`, {})
      .then(
        buildHTTPResponseHandler(`${serviceName}.downloadDetailsBase64Paper()`)
      );
  };

  static downloadMultipleDealsDetails = (dealerId, dealIds, zipFileName) => {
    const params = buildQueryParams({
      deals: dealIds.join(','),
      zipFileName,
    });

    return request
      .get(`${domain}/dealer/${dealerId}/inventory/v1.0/papers?${params}`, {})
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.downloadMultipleDealsDetails()`
        )
      );
  };

  static addSpotlightByDealId = (dealerId, dealId) => {
    return request
      .put(
        `${domain}/dealer/${dealerId}/inventory/v1.0/spotlight/${dealId}`,
        {}
      )
      .then(buildHTTPResponseHandler(`${serviceName}.addSpotlightByDealId()`));
  };

  static removeSpotlightByDealId = (dealerId, dealId) => {
    return request
      .delete(
        `${domain}/dealer/${dealerId}/inventory/v1.0/spotlight/${dealId}`,
        {}
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.removeSpotlightByDealId()`)
      );
  };

  static getLogs = (dealerId, dealId, page, size) => {
    return request
      .get(`${domain}/dealer/${dealerId}/inventory/v1.0/logs/${dealId}`, {
        page,
        size,
      })
      .then(buildHTTPResponseHandler(`${serviceName}.getLogs()`));
  };

  //CONFIGURATIONS

  static getStockConfiguration = dealerId => {
    return request
      .get(`${domain}/dealer/${dealerId}/inventory/v1.0/configuration`, {})
      .then(buildHTTPResponseHandler(`${serviceName}.getStockConfiguration()`));
  };

  static updateStockConfiguration = (dealerId, body) => {
    return request
      .put(`${domain}/dealer/${dealerId}/inventory/v1.0/configuration`, body)
      .then(
        buildHTTPResponseHandler(`${serviceName}.updateStockConfiguration()`)
      );
  };

  static vehicleInformationStep = (dealerId, dealId) => {
    return request
      .get(
        `${domain}/dealer/${dealerId}/inventory/v1.0/deal/${dealId}/vehicle-information`,
        {}
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.vehicleInformationStep()`)
      );
  };

  static financialSummaryStep = (dealerId, dealId) => {
    return request
      .get(
        `${domain}/dealer/${dealerId}/inventory/v1.0/deal/${dealId}/financial-summary`,
        {}
      )
      .then(buildHTTPResponseHandler(`${serviceName}.financialSummaryStep()`));
  };

  static getPaymentsFromDeal = (dealerId, dealId) => {
    return request
      .get(
        `${domain}/dealer/${dealerId}/inventory/v1.0/deal/${dealId}/payments`,
        {}
      )
      .then(buildHTTPResponseHandler(`${serviceName}.getPaymentsFromDeal()`));
  };

  static entryDocumentsStep = (dealerId, dealId) => {
    return request
      .get(
        `${domain}/dealer/${dealerId}/inventory/v1.0/deal/${dealId}/entry-documents`,
        {}
      )
      .then(buildHTTPResponseHandler(`${serviceName}.entryDocumentsStep()`));
  };

  static exitDocuments = (dealerId, dealId) => {
    return request
      .get(
        `${domain}/dealer/${dealerId}/inventory/v1.0/deal/${dealId}/exit-documents`,
        {}
      )
      .then(buildHTTPResponseHandler(`${serviceName}.entryDocumentsStep()`));
  };

  static paymentsStep = (dealerId, dealId) => {
    return request
      .get(
        `${domain}/dealer/${dealerId}/inventory/v1.0/deal/${dealId}/payments`,
        {}
      )
      .then(buildHTTPResponseHandler(`${serviceName}.paymentsStep()`));
  };

  static maintenancesStep = (dealerId, dealId) => {
    return request
      .get(
        `${domain}/dealer/${dealerId}/inventory/v1.0/deal/${dealId}/maintenances`,
        {}
      )
      .then(buildHTTPResponseHandler(`${serviceName}.maintenancesStep()`));
  };

  static proposalOrder = (dealerId, dealId) => {
    return request
      .get(
        `${domain}/dealer/${dealerId}/inventory/v1.0/deal/${dealId}/proposal-order`,
        {}
      )
      .then(buildHTTPResponseHandler(`${serviceName}.proposalOrder()`));
  };

  static finishFormByStep = (dealerId, dealMobiOneDto) => {
    return request
      .post(
        `${domain}/dealer/${dealerId}/inventory/v1.0/deal/form-by-step`,
        dealMobiOneDto
      )
      .then(buildHTTPResponseHandler(`${serviceName}.finishFormByStep()`));
  };

  static editFormByStep = (dealId, dealerId, dealMobiOneDto) => {
    return request
      .post(
        `${domain}/dealer/${dealerId}/inventory/v1.0/deal/form-by-step?dealId=${dealId}`,
        dealMobiOneDto
      )
      .then(buildHTTPResponseHandler(`${serviceName}.finishFormByStep()`));
  };

  static formByStepWithDealStrategyAndDealId = async (
    dealerId,
    dealMobiOneDto,
    dealStrategy,
    dealId
  ) => {
    return request
      .post(
        `${domain}/dealer/${dealerId}/inventory/v1.0/deal/form-by-step?dealId=${dealId}&dealStrategy=${dealStrategy}`,
        dealMobiOneDto
      )
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.formByStepWithDealStrategyAndDealId()`
        )
      );
  };

  static getOrderDetail = (dealerId, orderId) => {
    return request
      .get(
        `${domain}/dealer/${dealerId}/inventory/dms/v1.0/order-detail/${orderId}`
      )
      .then(buildHTTPResponseHandler(`${serviceName}.getOrderDetail()`));
  };
}
