import React, { useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';

import Alert from '@material-ui/lab/Alert';
import { CircularProgress } from '@material-ui/core';

import { useChatContext } from 'pages/chats/ChatsContext';

import GenericDialog from 'components/GenericDialog';
import ThemeButton from 'components/atoms/ThemeButton';
import TabPanel from 'components/StyledComponents/TabPanel';
import StyledTab from 'components/StyledComponents/StyledTab';
import StyledTabs from 'components/StyledComponents/StyledTabs';

import TextField from 'modules/financing/components/atoms/TextField';

import ItemHeader from '../atoms/ItemHeader';
import useToObtainGuid from './hooks/useToObtainGuid';
import ColdSimulation from './components/FormSimulation';
import LoadingInnerPages from 'components/LoadingInnerPages';
import SimulationHistory from './components/SimulationHistory';
import CreateSimulationCard from './components/CreateSimulationCard';
import DialogSelectVehicleToSimulate from './components/Dialogs/DialogSelectVehicleToSimulate';

import {
  FinancingSimulationProvider,
  useFinancingSimulationContext,
} from './context';

import { ContainerIframeModal, Container, Wrapper } from './styles';
import isEmpty from 'helpers/isEmpty';

const FinancingSimulation = () => {
  const [guild, setGuild] = useState(null);
  const [openModalFandi, setOpenModalFandi] = useState(false);
  const [urlModalFandi, setUrlModalFandi] = useState(null);

  const { currentDealer } = useSelector(state => state.dealerStore);

  const {
    createSimulationState,
    createSimulateFormValues,
    handleChangeSimulateForm,
    handleChangeNewSimulationValues,
    financingBancoPanEnabled,
    currentTab,
    handleChangeTab,
  }: any = useFinancingSimulationContext();

  const {
    currentProposal,
    getCurrentProposal,
    getInterestProducts,
  }: any = useChatContext();

  const sellerCpf = String(currentProposal?.sellerCpf);
  const clientCPF = currentProposal.clientUserCpfCnpj?.replace(/[.-]/g, '');

  const {
    fetchGuidMutation,
    downpaymentValue,
    handleValueChange,
  } = useToObtainGuid();

  const fandiIntegration = currentDealer.externalDealerIdentification.find(
    externalDealer => externalDealer.externalProvider === 'FANDI'
  );

  const DEFAULT_FANDI_VERSION = 1;
  const CHECKOUT_FANDI_3_DOT_0 = 2;
  const CHECKOUT_FANDI_4_DOT_0 = 3;
  const CHECKOUT_FANDI_4_DOT_1 = 3.1;

  const fandiVersion =
    currentDealer?.configs?.find(config => config.key === 'FANDI_VERSION')
      ?.value ?? DEFAULT_FANDI_VERSION;

  const isFandiDefaultVersion = Number(fandiVersion) === DEFAULT_FANDI_VERSION;
  const isCheckout3dot0 = Number(fandiVersion) === CHECKOUT_FANDI_3_DOT_0;
  const isFandi4dot0 = Number(fandiVersion) === CHECKOUT_FANDI_4_DOT_0;
  const isFandi4dot1 = Number(fandiVersion) === CHECKOUT_FANDI_4_DOT_1;

  async function handleFetchGuid() {
    if (isFandi4dot0 || isCheckout3dot0) {
      const FANDI_URL = `http://triumph.fandi.com.br/IntegracaoDMS.aspx?ReturnUrl=IntegracaoDMSInciarIntegracaoForm.aspx?Proposta=${currentProposal.id},cpf=${sellerCpf},CnpjLoja=${currentDealer.cnpj}`;
      window.open(FANDI_URL, '_blank').focus();
      return;
    }

    const { data } = await fetchGuidMutation.mutateAsync();

    if (data) {
      setGuild(data);
    }
  }

  async function handleFetchGuidFandi4dot0() {
    const currentProposalVehicle = currentProposal?.vehicle?.length
      ? currentProposal?.vehicle
      : undefined;
    const currentProposalVehiclePrice = currentProposalVehicle?.[0]?.price || 0;
    const FANDI_4_URL = `https://simulador-financiamento.gruposaga.com.br?emailVendedor=${currentProposal?.sellerMail}&idLead=${currentProposal.id}&idDealer=${currentDealer.id}&valorVeiculo=${currentProposalVehiclePrice}`;
    setUrlModalFandi(FANDI_4_URL);
    setOpenModalFandi(true);
  }

  const onCloseModalFandi = () => {
    setOpenModalFandi(false);
  };

  useEffect(() => {
    if (guild?.retorno) {
      setOpenModalFandi(true);
      setUrlModalFandi(
        `https://checkout.fandi.com.br/?token=${guild?.retorno}`
      );
    }
  }, [guild]);

  useEffect(() => {
    getCurrentProposal();
    getInterestProducts();
  }, []);

  if (isEmpty(currentProposal)) {
    return <LoadingInnerPages />;
  }

  const hasVehicle = !!currentProposal?.vehicle?.length;

  return (
    <>
      <ItemHeader>Financiamento</ItemHeader>
      {fandiIntegration ? (
        <div>
          <StyledTabs
            value={currentTab}
            onChange={handleChangeTab}
            variant="fullWidth"
          >
            <StyledTab value={0} label="Simulação" />
            <StyledTab value={1} label="Minhas Simulações" />
          </StyledTabs>
          <TabPanel value={currentTab} index={0} key={'estoque'}>
            {currentTab === 0 && (
              <Wrapper>
                {!clientCPF && (
                  <Alert severity="info">{`Recomendamos preencher o CPF do cliente vinculado à proposta. Caso necessário, você pode adicionar essa informação na aba "Cliente" antes de prosseguir.`}</Alert>
                )}
                {!hasVehicle && (
                  <Alert severity="error">{`Para continuar, é necessário adicionar um veículo à proposta. Por favor, selecione um veículo na aba "Veículos" antes de prosseguir.`}</Alert>
                )}
                {currentDealer?.additionalValueOfProductsOnFinancing !== 0 &&
                  isFandiDefaultVersion && (
                    <NumberFormat
                      label="Valor de entrada"
                      value={downpaymentValue}
                      onValueChange={handleValueChange}
                      thousandSeparator="."
                      decimalSeparator=","
                      allowNegative={false}
                      allowLeadingZeros={true}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      prefix="R$ "
                      customInput={TextField}
                      mini
                    />
                  )}
                <ThemeButton
                  onClick={() => handleFetchGuid()}
                  fullWidth
                  disabled={!hasVehicle}
                >
                  {fetchGuidMutation.isLoading ? (
                    <CircularProgress color="inherit" />
                  ) : isFandi4dot0 || isFandi4dot1 ? (
                    'Análise F&I'
                  ) : (
                    'Simulação Rápida'
                  )}
                </ThemeButton>

                {(isFandi4dot0 || isFandi4dot1) && (
                  <ThemeButton
                    onClick={() => handleFetchGuidFandi4dot0()}
                    fullWidth
                    disabled={!hasVehicle}
                  >
                    {fetchGuidMutation.isLoading ? (
                      <CircularProgress color="inherit" />
                    ) : (
                      'Simulação Rápida'
                    )}
                  </ThemeButton>
                )}
              </Wrapper>
            )}
          </TabPanel>
          <TabPanel value={currentTab} index={1} key={'estoque'}>
            {currentTab === 1 && (
              <Container>
                {/* //SIMULATION_HISTORY */}
                <SimulationHistory />
              </Container>
            )}
          </TabPanel>
        </div>
      ) : financingBancoPanEnabled ? (
        <div>
          <StyledTabs
            value={currentTab}
            onChange={handleChangeTab}
            variant="fullWidth"
          >
            <StyledTab value={0} label="Simulação" />
            <StyledTab value={1} label="Minhas Simulações" />
          </StyledTabs>
          <TabPanel value={currentTab} index={0} key={'estoque'}>
            {currentTab === 0 && (
              <Container>
                {/* //CREATE_SIMULATION */}
                <CreateSimulationCard
                  handleClickCreateSimulation={() => {
                    handleChangeNewSimulationValues({
                      target: {
                        name: 'selectVehicleDialogOpen',
                        value: true,
                      },
                    });
                  }}
                />
              </Container>
            )}
          </TabPanel>
          <TabPanel value={currentTab} index={1} key={'estoque'}>
            {currentTab === 1 && (
              <Container>
                <>
                  {/* //SIMULATION_HISTORY */}
                  {!createSimulateFormValues?.vehicle?.id && (
                    <SimulationHistory />
                  )}
                </>
              </Container>
            )}
          </TabPanel>
        </div>
      ) : (
        <ColdSimulation />
      )}
      {/* //SELECT_VEHICLE_TO_CREATE_SIMULATE */}
      {createSimulationState.selectVehicleDialogOpen && (
        <DialogSelectVehicleToSimulate
          open={createSimulationState.selectVehicleDialogOpen}
          handleClickSelectVehicleToSimulate={({ vehicle }) => {
            handleChangeNewSimulationValues({
              target: { name: 'simulateResponse', value: null },
            });
            handleChangeSimulateForm({
              target: {
                name: 'vehicle',
                value: vehicle,
              },
            });
            handleChangeSimulateForm({
              target: {
                name: 'vehicle.price',
                value: vehicle?.price || 0,
              },
            });
          }}
          handleClose={() =>
            handleChangeNewSimulationValues({
              target: {
                name: 'selectVehicleDialogOpen',
                value: false,
              },
            })
          }
        />
      )}
      {openModalFandi && (
        <GenericDialog
          open={openModalFandi}
          onClose={onCloseModalFandi}
          title={'Simulação Rápida'}
          maxWidth="md"
          OnCloseButton
        >
          <ContainerIframeModal>
            <iframe
              src={urlModalFandi}
              width={'100%'}
              style={{ height: 'calc(100vh - 130px)' }}
            />
          </ContainerIframeModal>
        </GenericDialog>
      )}
    </>
  );
};

const FinancingSimulationMain = () => (
  <FinancingSimulationProvider>
    <FinancingSimulation />
  </FinancingSimulationProvider>
);

export default React.memo(FinancingSimulationMain);
