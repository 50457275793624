import React from 'react';

import TextComponent from 'modules/mobiOne/components/Text/TextComponent';

import Wrapper from 'components/Wrapper';
import getImage from 'components/getImage';
import { TextBubbleType } from '../components/PreviewMessage';
import * as S from '../components/PreviewMessage/styles';

import ListIcon from '@material-ui/icons/List';
import PreViewMessage from 'components/PreViewMessage';

export const FooterButton = ({
  listButton,
  buttons,
}: {
  listButton?: string;
  buttons?: {
    id?: string | number;
    label: string;
  }[];
}) => {
  return (
    <>
      {listButton && (
        <Wrapper flex wrap="wrap" gap="sm" width="100%" mt="sm">
          <S.OptionsButton disabled={true}>
            <ListIcon />
            {listButton}
          </S.OptionsButton>
        </Wrapper>
      )}

      {buttons && (
        <Wrapper flex wrap="wrap" width="100%" gap="sm" mt="sm">
          {buttons?.map(
            (btn, index) =>
              btn.label && (
                <S.OptionsButton disabled={true} key={`${index}-button`}>
                  {btn?.label}
                </S.OptionsButton>
              )
          )}
        </Wrapper>
      )}
    </>
  );
};

const TextBubble = ({
  message,
  imageUrl,
  footerText,
  headerText,
  messageProps,
}: TextBubbleType & { imageUrl?: any; messageProps?: any }) => {
  const [openImage, setOpenImage] = React.useState(false);

  return (
    <>
      {imageUrl && (
        <img
          style={{
            width: '100%',
            overflow: 'hidden',
            borderRadius: '6px',
          }}
          onClick={() => setOpenImage(true)}
          src={
            typeof imageUrl === 'string'
              ? imageUrl
              : getImage({
                  imageId: imageUrl?.imageId,
                  imageVcode: imageUrl?.imageVCode,
                })
          }
          width="100%"
          loading="lazy"
        />
      )}
      {headerText && (
        <TextComponent fontSize="18px" fontWeight={600} color="#fff">
          {headerText}
        </TextComponent>
      )}
      <TextComponent
        fontSize="14px"
        lineHeight="20px"
        lineBreak="anywhere"
        whiteSpace="pre-wrap"
        color="#fff"
        extraStyle={{ marginBottom: 8 }}
      >
        {message}
      </TextComponent>
      {footerText && (
        <TextComponent
          fontSize="14px"
          whiteSpace="pre-wrap"
          color="#fff"
          lineHeight="28px"
          extraStyle={{ marginBottom: 8, fontStyle: 'italic' }}
        >
          {footerText}
        </TextComponent>
      )}
      {openImage && (
        <PreViewMessage
          open={openImage}
          handleOnClose={() => setOpenImage(false)}
          imageUrl={imageUrl}
          message={{ ...messageProps }}
          date={messageProps?.dateText}
          ownerName={messageProps?.ownerName || messageProps?.sellerName}
        />
      )}
    </>
  );
};

export default TextBubble;
