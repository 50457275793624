/* eslint-disable no-case-declarations */
import { TemplateMessage } from '../components/PreviewMessage';
import { ProposalMessageTemplate } from '../services/dto';

import EnvVars from 'helpers/envVars';

const imageBaseUrl = EnvVars('API_IMAGES_BASE_URL');

const buildImageUrl = (path: string) =>
  `${imageBaseUrl}/${path.split('/api/')?.[1] || ''}`;

const mapButtons = actions =>
  actions?.map(({ id, title }) => ({
    id,
    label: title,
  }));

export const buildTemplateMessage = (
  template: ProposalMessageTemplate
): TemplateMessage => {
  const templateContent = template?.content;
  const contentType = template?.contentType;

  if (!templateContent) return { message: template?.message };

  switch (contentType) {
    case 'WHATSAPP_CARD': {
      const { headerText, footer, actions, paths } =
        templateContent?.whatsappCard || {};
      return {
        message: template?.message,
        headerText,
        footerText: footer,
        buttons: mapButtons(actions),
        imageUrl: paths?.[0] ? buildImageUrl(paths?.[0] || '') : null,
      };
    }

    case 'BUTTON': {
      const { actions } = templateContent?.quickReply || {};
      return {
        message: template?.message,
        buttons: mapButtons(actions),
      };
    }

    case 'LIST': {
      const { button } = templateContent?.listPicker || {};
      return {
        message: template?.message,
        listButton: button,
      };
    }

    case 'IMAGE': {
      const { path } = templateContent?.media || {};
      return {
        message: template?.message,
        imageUrl: path ? buildImageUrl(path || '') : null,
      };
    }

    case 'TEXT': {
      return { message: template?.message || '' };
    }

    default:
      return { message: template?.message };
  }
};

export const buildMessage = (template: ProposalMessageTemplate): string => {
  const templateContent = template?.content;
  const contentType = template?.contentType;

  if (!templateContent) return template?.message || '';

  switch (contentType) {
    case 'WHATSAPP_CARD':
      return templateContent.whatsappCard?.body || '';

    case 'BUTTON':
      return templateContent.quickReply?.body || '';

    case 'LIST':
      return templateContent.listPicker?.body || '';

    case 'IMAGE':
      return templateContent.media?.body || '';

    case 'TEXT':
      return templateContent.text?.body || '';

    default:
      return template?.message || '';
  }
};
