import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';

import ThemeButton from 'components/atoms/ThemeButton';

import { QuoteDialog } from './QuoteDialog';

interface Props {
  buttonLabel?: string;
  iframeParams: string;
  viewMode?: 'order' | 'proposal';
}

export function StartQuoteFlow({
  buttonLabel = 'Cotar Seguros',
  iframeParams,
  viewMode = 'proposal',
}: Props) {
  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);

  const { currentDealer, proposalId } = useSelector(state => ({
    currentDealer: state.dealerStore.currentDealer,
    proposalId: state.proposalStore.currentProposal.proposalId,
  }));

  const handleOpenDialog = () => setDialogIsOpen(true);
  const handleCloseDialog = () => setDialogIsOpen(false);

  const handleStartInsuranceQuote = () => {
    const currentDealerId = currentDealer?.id;

    if (!currentDealerId || !proposalId) return;

    return handleOpenDialog();
  };

  return (
    <Fragment>
      <ThemeButton onClick={() => handleStartInsuranceQuote()}>
        {buttonLabel}
      </ThemeButton>
      <QuoteDialog
        dialogTitle={buttonLabel}
        iframeParams={iframeParams}
        isOpen={dialogIsOpen}
        onClose={() => handleCloseDialog()}
        viewMode={viewMode}
      />
    </Fragment>
  );
}
