import { DealerFromStore } from 'components/withDealer';

const getRecursivePath = (path, pathValuesObj = {}) => {
  let auxPath = path;

  if (!pathValuesObj?.dealerId) {
    const currentDealerOnLocalStore = DealerFromStore.getDealerFromLocalStorage();
    pathValuesObj.dealerId = currentDealerOnLocalStore?.id;
  }

  Object.keys(pathValuesObj).forEach((key, index) => {
    auxPath = auxPath.replace(`:${key}`, Object.values(pathValuesObj)[index]);
  });
  return auxPath;
};

const initialPath = '/mobigestor/:dealerId';

export default {
  //LANDING_PAGE_MODULE
  landing: '/',
  //FIRST_ACCESS_MODULE
  // ---> verificar pagina
  firstAccess: `/mobigestor/primeiro-acesso`,
  //REDIRECT_PAGE
  redirectPath: '/mobigestor/',
  //CHECK_AND_REDIRECT_PAGE
  checkAndRedirect: '/mobigestor/checkAndRedirect',
  //MY_RESELLERS_PAGE
  myResellers: `/mobigestor/minhas-revendas`,
  // --->verificar-pagina
  welcome: `${initialPath}/bem-vindo`,
  //HOME
  mobigestorHome: initialPath,
  mobigestorHomeByDealerId: initialPath,
  // FEIRÃO
  feirao: `${initialPath}/feirao`,
  //EVALUATION_MODULE
  evaluations: `${initialPath}/avaliacao/lista`,
  evaluationReports: `${initialPath}/avaliacao/relatorios`,
  evaluationConfig: `${initialPath}/avaliacao/configuracao`,
  patioSystemControl: `${initialPath}/gestao-de-patio`,
  patioRegister: `${initialPath}/gestao-de-patio/registro`,
  patioEdit: `${initialPath}/gestao-de-patio/editar/:id`,
  patioVehicleDetails: `${initialPath}/gestao-de-patio/detalhes-do-veiculo/:id`,
  successfulRegistration: `${initialPath}/gestao-de-patio/sucesso`,
  newEvaluation: `${initialPath}/avaliacao/nova-avaliacao`,
  //MY_DEALS_MODULE
  // ---> TODO: verificar se vai ser utilizado
  // myDealBase: `${initialPath}/estoque`,
  myDeals: `${initialPath}/estoque/meusanuncios`,
  dealForm: `${initialPath}/estoque/incluiranuncios`,
  updateDeal: `${initialPath}/estoque/incluiranuncios/update/:id`,
  performance: `${initialPath}/estoque/performance`,
  stockPublicationSettings: `${initialPath}/estoque/configuracoes`,
  // ---> TODO: verificar se vai ser utilizado
  // ---> settings: '${initialPath}/configuracoes',
  // ---> TODO: verificar se vai ser utilizado
  // myDealsImprovements:
  //   '${initialPath}/estoque/meusanuncios/melhorias/:improvement',
  //LEAD_MANAGER_MODULE
  // ---> TODO: verificar se vai ser utilizado
  // ---> settings: '${initialPath}/configuracoes',
  // ---> TODO: verificar se vai ser utilizado
  // chatProposal: '${initialPath}/proposta',
  proposals: `${initialPath}/propostas`,
  conversations: `${initialPath}/conversations`,
  approvals: `${initialPath}/aprovacoes`,
  manageReports: `${initialPath}/relatorios`,
  proposalsConfig: `${initialPath}/propostas/configuracao`,
  proposalOrderById: `${initialPath}/proposta/:proposalId`,
  goalManager: `${initialPath}/gestor-de-metas`,
  actionPlansByDealerId: `${initialPath}/mobigestor/planos-de-acao`,
  sellerWallet: `${initialPath}/mobigestor/carteira-vendedor`,
  // chatProposalByProposalId: `${initialPath}/proposta/:proposalId`,
  storeInvetory: `${initialPath}/store-inventory`,
  corporateSalesManager: `${initialPath}/gestor-venda-corporativa`,
  corporateSalesManagerConfig: `${initialPath}/gestor-venda-corporativa/configuracao`,
  reception: `${initialPath}/recepcao`,
  //SVM_MODULE
  financing: `${initialPath}/financiamento`,
  newFinancingSteps: `${initialPath}/financiamento/novo`,
  financingDetailsByFinancingId: `${initialPath}/financiamento/detalhes/:financingId`,
  financialInstitutionsList: `${initialPath}/svm/instituicoes-financeiras`,
  financialInstitutionDetails: `${initialPath}/svm/instituicao/:institutionId`,
  // ---> TODO: verificar se vai ser utilizado
  // financingConfigInstitution: '${initialPath}/svm/configuracoes/instituicao',
  newCampaign: `${initialPath}/svm/campanha/nova`,
  editCampaign: `${initialPath}/svm/campanha/editar/:campaignId`,
  newFinancialInstitution: `${initialPath}/svm/instituicao/nova`,
  newTable: `${initialPath}/svm/tabela/nova`,
  editTable: `${initialPath}/svm/tabela/editar/:tableId`,
  //RESALE_MODULE
  resaleBase: `${initialPath}/mobirepasse`,
  resaleDealForm: `${initialPath}/mobirepasse/inclusao-anuncio`,
  resaleDeaFormEdit: `${initialPath}/mobirepasse/editar-anuncio/:dealId`,
  resaleStock: `${initialPath}/mobirepasse/vender/meus-anuncios`,
  resaleProposals: `${initialPath}/mobirepasse/vender/propostas`,
  resaleProposalsByDeal: `${initialPath}/mobirepasse/vender/proposta/:proposalId`,
  resaleBuyProposals: `${initialPath}/mobirepasse/comprar/propostas`,
  resaleBuyNegotiationByDeal: `${initialPath}/mobirepasse/comprar/proposta/:proposalId`,
  resaleBuyOfferPortal: `${initialPath}/mobirepasse/comprar/portal-ofertas`,
  resaleBuyOfferPortalByDealer: `${initialPath}/mobirepasse/comprar/:stockDealerId/portal-ofertas`,
  resaleDealDetails: `${initialPath}/mobirepasse/comprar/portal-ofertas/detalhes/:dealId`,
  resaleProposalSendSuccessful: `${initialPath}/mobirepasse/comprar/portal-ofertas/proposta-enviada`,
  resalePurchaseSuccessful: `${initialPath}/mobirepasse/comprar/portal-ofertas/compra-realizada`,
  resaleConfig: `${initialPath}/mobirepasse/configuracao`,
  //CONTACTS_MODULE
  contacts: `${initialPath}/contatos`,
  contactsCustomers: `${initialPath}/contatos/clientes`,
  contactsSuppliers: `${initialPath}/contatos/fornecedores`,
  editContact: `${initialPath}/editar-contato/:contactId`,
  createContact: `${initialPath}/criar-contato`,
  //SCHEDULE_MODULE
  resaleSchedulerContacts: `${initialPath}/agenda`,
  //INTEGRATION_MODULE
  manageProviders: `${initialPath}/integracoes`,
  //MOBI-FONE_MODULE
  mobiPhone: `${initialPath}/mobifone`,
  //DEALER_SITE_MODULE
  dealerSite: `${initialPath}/dealersite`,
  //SETTINGS_MODULE
  // ---> TODO: verificar se vai ser utilizado
  // ---> settings: `${initialPath}/configuracoes`,
  formDealer: `${initialPath}/configuracoes/dealer-form`,
  //CALL_CENTER_MODULE
  callCenter: `/mobigestor/callcenter`,
  // chatProposalCallCenter: `/mobigestor/proposta/:proposalId/callcenter`,
  //SEARCH_AND_RESCUE_CENTER_MODULE
  searchAndRescueCenter: `/mobigestor/busca-e-resgate`,
  // chatProposalSearchAndRescue: `/mobigestor/proposta/:proposalId/busca-e-resgate`,
  //GROUP_VIEW_MODULE
  groupView: `/mobigestor/group-view`,
  groupDashboard: `/mobigestor/group-view/dashboard`,
  groupViewStock: `/mobigestor/group-view/estoque`,
  groupViewProposals: `/mobigestor/group-view/propostas`,
  groupViewApprovals: `/mobigestor/group-view/aprovacoes`,
  groupViewEvaluations: `/mobigestor/group-view/avaliacoes`,
  groupViewMobiPhone: `/mobigestor/group-view/mobifone`,
  groupViewActionPlans: `/mobigestor/group-view/planos-de-acao`,
  groupViewActionPlansByDealerId: `/mobigestor/group-view/planos-de-acao/:dealerId`,
  groupViewBillingCenter: `/mobigestor/group-view/central-de-faturamento`,

  //REGISTER_DEALER
  actionvationDealer: `/ativacao`,
  //ERROR_500_PAGE
  error500: `${initialPath}/error500`,
  //TODO: verificar se vai ser utilizado
  // bills: `${initialPath}/faturas`,
  //TODO: verificar se vai ser utilizado
  mobifi: `${initialPath}/mobifi`,
  //TODO: verificar se vai ser utilizado
  // formCadastro: `/form-cadastro`,
  //TODO: verificar se vai ser utilizado
  // register: `/cadastro`,
  //TODO: verificar se vai ser utilizado
  // intendFlow: `/cadastro-empresa`,
  //TODO: verificar se vai ser utilizado
  // intendFlowSecond: `/cadastro-empresa/:plano`,
  financialGeneralView: `${initialPath}/financeiro/visao-geral`,
  financialNewEntry: `${initialPath}/financeiro/novo-lancamento`,
  financialVendorsAnalysis: `${initialPath}/financeiro/analise-vendedores`,
  financialConfiguration: `${initialPath}/financeiro/configuracoes`,
  manageDeals: `${initialPath}/gestao-veiculos/meus-veiculos`,
  dealInfos: `${initialPath}/gestao-veiculos/veiculo`,
  managePendencies: `${initialPath}/gestao-veiculos/pendencias`,
  billings: `${initialPath}/minhas-faturas`,
  //SAGA_SEMINOVOS
  sagaSeminovosIframe: `${initialPath}/saga-seminovos`,
  //MobiOne
  mobiOneStoreInvetory: `${initialPath}/mobione/estoque`,
  mobiOneStoreInvetoryAddVehicle: `${initialPath}/mobione/estoque/adicionar-veiculo`,
  mobiOneStoreInvetoryEditVehicle: `${initialPath}/mobione/estoque/editar-veiculo/:id`,
  mobiOneVehicleDetails: `${initialPath}/mobione/estoque/detalhes-do-veiculo/:id`,
  mobiOneFinancial: `${initialPath}/mobione/financeiro`,
  mobiOnePaymentsAndTransfers: `${initialPath}/mobione/pagamentos-e-transferencias`,
  mobiOneForwardingAgent: `${initialPath}/mobione/despachante`,
  mobiOneInvoice: `${initialPath}/mobione/fiscal`,
  mobiOneSettings: `${initialPath}/mobione/configuracoes`,
  getRecursivePath,
  //CRM
  crmCampaigns: `${initialPath}/crm/campanhas`,
  crmCreateCampaign: `${initialPath}/crm/campanhas/nova-campanha`,
  crmUpdateCampaign: `${initialPath}/crm/campanhas/editar-campanha/:id`,
  crmTemplates: `${initialPath}/crm/templates`,
  //AUTOMATION
  automation: `${initialPath}/crm/automacoes`,
  //MobiAnalytics
  mobiAnalytics: `${initialPath}/mobi-analytics`,
  //MECHANICAL_WARRANTY
  mechanicalWarranty: `${initialPath}/garantia-mecanica`,
  //LEAD_QUALIFIER
  leadQualifier: `${initialPath}/qualificador-de-leads`,
  //WHATS_APP_SENDER
  whatsappSender: `${initialPath}/whatsapp-sender`,
  createWhatsappSender: `${initialPath}/whatsapp-sender/criar-novo-sender`,
};
