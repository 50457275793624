const MessageTypes = {
  AUDIO: 'AUDIO',
  VIDEO: 'VIDEO',
  IMAGE: 'IMAGE',
  TEXT: 'TEXT',
  MEDIA: 'MEDIA',
  RESPONSE: 'RESPONSE',
  TEMPLATE: 'TEMPLATE',
  DEFAULT: 'DEFAULT',
};
export default MessageTypes;
