import request from 'helpers/request';
import EnvVar from 'helpers/envVars';
import buildHTTPResponseHandler from 'helpers/buildHTTPResponseHandler';
import { buildQueryParams } from 'helpers/buildQueryParams';

const domain = EnvVar('API_PAINEL_DIGITAL_BASE_URL');
const serviceName = 'MechanicalWarrantyServices';

export default class MechanicalWarrantyServices {
  static getEligibilityMechanicalWarranty = async (
    dealerId: number,
    molicarId: number,
    plate: string,
    modelYear: number
  ) => {
    const params = buildQueryParams({ modelYear, plate });
    const buildedQueryParams = params ? `?${params}` : '';

    const response: any = await request.put(
      `${domain}/dealer/${dealerId}/insurance/eligibility/mechanical-warranty/molicar/${molicarId}/v1.0${buildedQueryParams}`,
      { dealerId, molicarId, modelYear, plate }
    );

    const responseHandler = buildHTTPResponseHandler(
      `${serviceName}.getEligibilityMechanicalWarranty()`
    );

    return responseHandler(response);
  };

  static getHasMechanicalWarranty = async (
    dealerId: number,
    dealId: number
  ) => {
    const response: any = await request.get(
      `${domain}/dealer/${dealerId}/insurance/policy/mechanical-warranty/has-mechanical-warranty/deal/${dealId}/v1.0`
    );

    const responseHandler = buildHTTPResponseHandler(
      `${serviceName}.getHasMechanicalWarranty()`
    );

    return responseHandler(response);
  };
}
