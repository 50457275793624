import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { addDays, format } from 'date-fns';

import ThemeButton from 'components/atoms/ThemeButton';

import useSnackBar from 'hooks/useSnackBar';
import { useChatContext } from 'pages/chats/ChatsContext';

import { QuotesList } from 'modules/insureQuotes/components/QuotesList';
import { StartQuoteFlow } from 'modules/insureQuotes/components/StartQuoteFlow';
import {
  buildCivilStatusToInsurance,
  getParamsToCreateInsuranceProposal,
} from 'modules/insureQuotes/utils/functions';

import {
  useSendInsuranceQuoteToSpecialist,
  // * [Temporarily disabled]
  // useSendInsuranceQuoteToCustomer,
} from 'modules/insureQuotes/services/queries';

import * as S from './styles';

export function InsuranceQuote() {
  const { error, success } = useSnackBar();

  const { currentProposal }: any = useChatContext();

  const { currentVehicle, buyerCurrentData } = useSelector(
    state => state.order
  );

  const {
    mutateAsync: sendInsuranceQuoteToSpecialist,
    isLoading: loadingSendInsuranceQuoteToSpecialist,
  } = useSendInsuranceQuoteToSpecialist({
    onError: () => {
      return error('Desculpe, ocorreu um erro inesperado.');
    },
    onSuccess: () => {
      return success('Enviado para especialista.');
    },
  });

  // * [Temporarily disabled]
  /* 
  const {
    mutateAsync: sendInsuranceQuoteToCustomer,
    isLoading: loadingSendInsuranceQuoteToCustomer,
  } = useSendInsuranceQuoteToCustomer({
    onError: () => {
      return error('Desculpe, ocorreu um erro inesperado.');
    },
    onSuccess: () => {
      return success('Enviado para cliente.');
    },
  });
  */

  const createIframeParams = (): string => {
    try {
      const today = new Date();
      const futureDate = addDays(today, 3);
      const formattedTodayMoreThreeDaysDate = format(futureDate, 'dd/MM/yyyy');

      const formattedParams = getParamsToCreateInsuranceProposal({
        clientEmail: buyerCurrentData?.email,
        clientUserCpfCnpj: buyerCurrentData?.cpf,
        clientUserName: buyerCurrentData?.name,
        clientUserPhone: buyerCurrentData?.phone,
        modelOrModelName: currentVehicle?.model || currentVehicle?.modelName,
        modelYear: currentVehicle?.modelYear,
        plate: currentVehicle?.plate,
        productionYear: currentVehicle?.currentVehicle,
        proposalId: currentProposal.id,
        vin: currentVehicle?.vin,
        clientCivilStatus:
          buildCivilStatusToInsurance?.[buyerCurrentData.civilStatus]?.value ??
          '',
        clientGender:
          typeof buyerCurrentData?.gender === 'string'
            ? buyerCurrentData.gender.charAt(0).toUpperCase()
            : '',
        clientBirthDate: buyerCurrentData?.birthDate
          ? format(new Date(buyerCurrentData.birthDate), 'dd/MM/yyyy')
          : '',
        clientZipCode: buyerCurrentData?.zipcode,
        todayMoreThreeDays1404: formattedTodayMoreThreeDaysDate,
        todayMoreThreeDays1405: formattedTodayMoreThreeDaysDate,
      });

      return formattedParams;
    } catch {
      return '';
    }
  };

  const handleClickCallToSpecialist = useCallback(async () => {
    await sendInsuranceQuoteToSpecialist({
      dealerId: currentProposal.dealerId,
      proposalId: currentProposal.id,
    });
  }, [currentProposal.dealerId, currentProposal.id]);

  // * [Temporarily disabled]
  /*
  const handleClickCallToClient = useCallback(async () => {
    await sendInsuranceQuoteToCustomer({
      dealerId: currentProposal.dealerId,
      proposalId: currentProposal.id,
    });
  }, [currentProposal.dealerId, currentProposal.id]);
  */

  return (
    <S.Container>
      <S.QuotesContainer>
        <QuotesList viewMode="order" />
      </S.QuotesContainer>
      <S.Buttons>
        {/* [Temporarily disabled] */}
        {/* <ThemeButton
          onClick={handleClickCallToClient}
          loading={loadingSendInsuranceQuoteToCustomer}
          disabled={loadingSendInsuranceQuoteToCustomer}
        >
          Enviar para cliente
        </ThemeButton> */}
        <ThemeButton
          onClick={handleClickCallToSpecialist}
          loading={loadingSendInsuranceQuoteToSpecialist}
          disabled={loadingSendInsuranceQuoteToSpecialist}
        >
          Enviar para especialista
        </ThemeButton>
        <StartQuoteFlow
          buttonLabel="Cotar agora"
          iframeParams={createIframeParams()}
          viewMode="order"
        />
      </S.Buttons>
    </S.Container>
  );
}
