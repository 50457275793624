import Keycloak, { KeycloakInitOptions } from 'keycloak-js';

import env from 'helpers/envVars';
import routesPath from 'config/routesPath';

let authenticated = false;

let user = {};

const keycloakInstance = new Keycloak({
  realm: env('KEYCLOAK_REALM'),
  url: env('KEYCLOAK_URL'),
  clientId: env('KEYCLOAK_CLIENT_ID'),
});

const getInitOptions = ({
  checkLoginIframe = false,
  token = '',
}): KeycloakInitOptions => {
  return {
    onLoad: 'login-required',
    checkLoginIframe,
    ...(!!token && { token }),
  };
};

const init = async () => {
  try {
    authenticated = await keycloakInstance.init(
      getInitOptions({ checkLoginIframe: true })
    );
    return authenticated;
  } catch {
    return false;
  }
};

const tryAuthenticated = async () => {
  try {
    authenticated = await keycloakInstance
      .init(getInitOptions({}))
      .then(authenticated => authenticated);
    return authenticated;
  } catch {
    return false;
  }
};

const loginWithToken = async token => {
  try {
    authenticated = await keycloakInstance
      .init(getInitOptions({ token }))
      .then(authenticated => authenticated);

    return authenticated;
  } catch {
    return false;
  }
};

const getProfile = async () => {
  try {
    user = await keycloakInstance.loadUserProfile().then(profile => profile);
    return user;
  } catch {
    return false;
  }
};

const getRefreshToken = () => keycloakInstance.refreshToken;

const getToken = () => keycloakInstance.token;

const isLogged = () => !!keycloakInstance.token;

const login = () => {
  keycloakInstance.init(getInitOptions({ checkLoginIframe: true }));

  keycloakInstance.login({
    redirectUri: routesPath.getRecursivePath(routesPath.mobigestorHome),
  });
};

const logout = () => keycloakInstance.logout();

const register = () => keycloakInstance.register();

const updatePassword = () => {
  const redirectUri = routesPath.getRecursivePath(routesPath.mobigestorHome);
  return keycloakInstance.login({ redirectUri, action: 'UPDATE_PASSWORD' });
};

const updateToken = () => keycloakInstance.updateToken(50);

export {
  getProfile,
  getRefreshToken,
  getToken,
  init,
  isLogged,
  keycloakInstance,
  login,
  loginWithToken,
  logout,
  register,
  tryAuthenticated,
  updatePassword,
  updateToken,
};
