import styled from 'styled-components';

export const Container = styled.div`
  background-color: #f4f4f4;
  border-radius: 8px;
  padding: 16px;
  width: auto;
  min-height: 400px;
  font-family: 'Arial', sans-serif;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 8px;
  border-bottom: 1px solid #ddd;
`;

export const UserInfo = styled.div`
  margin-left: 10px;
`;

export const MessageDate = styled.div`
  font-size: 12px;
  color: #aaa;
`;

export const OptionsButton = styled.button`
  background-color: white;
  border: 1px solid #ddd;
  padding: 10px;
  font-size: 14px;
  color: #555;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  flex: 1;
  min-width: calc(50% - 4px);
`;
