import React, { ChangeEvent } from 'react';
import { format } from 'date-fns';

import { FormControlLabel, Radio } from '@material-ui/core';

import {
  InsuranceQuoteData,
  InsuranceQuoteStatus,
} from 'modules/insureQuotes/services/dto';

import * as S from './styles';
import { Alert } from '@material-ui/lab';

interface Props {
  isLoading: boolean;
  onSelectQuote: (quoteId: number) => void;
  quote: InsuranceQuoteData;
  quoteIndex: number;
  selectedQuoteId?: number;
}

export function QuoteItem({
  isLoading,
  onSelectQuote,
  quote,
  quoteIndex,
  selectedQuoteId,
}: Props) {
  const {
    id,
    coverType,
    cpfCnpj,
    financingInstitution,
    name,
    quoteDate,
    status,
    value,
    quoteId,
    syncMessage,
  } = quote;

  const isChecked = id === selectedQuoteId;

  const disabledOption =
    isLoading || status === InsuranceQuoteStatus.New || !!syncMessage;

  const formatQuoteDate = (timestamp: number) => {
    return format(new Date(timestamp), 'dd/MM/yyyy');
  };

  const formatCurrency = (amount: number) => {
    return amount.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    });
  };

  const DetailItem = ({ label, value }: { label: string; value: string }) => (
    <S.Details>
      <span>{label}:</span> <span className="value">{value}</span>
    </S.Details>
  );

  const handleSelectQuote = (event: ChangeEvent<HTMLInputElement>) => {
    return onSelectQuote(Number(event.target.value));
  };

  return (
    <S.Container data-is-checked={isChecked}>
      <S.Content>
        <S.Header>
          <FormControlLabel
            value={id}
            label={`Cotação ${quoteIndex + 1}`}
            control={
              <Radio
                checked={isChecked}
                color="primary"
                disabled={disabledOption}
                name="radio-quote-item"
                onChange={handleSelectQuote}
                value={id}
              />
            }
          />
          <span>ID: {quoteId}</span>
        </S.Header>
        {quoteDate && <span>{formatQuoteDate(quoteDate)}</span>}
      </S.Content>
      <S.Divider />
      <S.Content>
        {syncMessage && <Alert severity="error">{syncMessage}</Alert>}
        {value && <DetailItem label="Valor" value={formatCurrency(value)} />}
        {name && <DetailItem label="Responsável" value={name} />}
        {cpfCnpj && <DetailItem label="CPF/CNPJ" value={cpfCnpj} />}
        {financingInstitution && (
          <DetailItem label="Seguradora" value={financingInstitution} />
        )}
        {coverType && (
          <DetailItem label="Tipo de cobertura" value={coverType} />
        )}
      </S.Content>
    </S.Container>
  );
}
