import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Dialog, IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

import * as S from './styles';
import { useUnmarkInsuranceQuotes } from 'modules/insureQuotes/services/queries';

interface Props {
  dialogTitle: string;
  iframeParams: string;
  isOpen: boolean;
  onClose: () => void;
  viewMode?: 'order' | 'proposal';
}

export function QuoteDialog({
  dialogTitle,
  iframeParams,
  isOpen,
  onClose,
  viewMode = 'proposal',
}: Props) {
  const { currentDealer } = useSelector(state => ({
    currentDealer: state.dealerStore.currentDealer,
  }));
  const { currentProposal } = useSelector(state => state.proposalStore);

  const quiverPW = currentDealer.externalDealerIdentification.find(
    externalDealer => externalDealer.externalProvider === 'QUIVER'
  )?.externalIdentification;

  const quiverOrderPW = currentDealer.externalDealerIdentification.find(
    externalDealer => externalDealer.externalProvider === 'QUIVER_ORDER'
  )?.externalIdentification;

  const iframeUrl = `https://www.corretor-online.com.br/canalcliente/index.htm?Pw=${
    viewMode === 'order' && !!quiverOrderPW ? quiverOrderPW : quiverPW
  }&${iframeParams}`;

  const [timeoutId, setTimeoutId] = useState<number | null>(null);

  const { mutateAsync: unmarkInsuanceQuotes } = useUnmarkInsuranceQuotes();

  useEffect(() => {
    if (isOpen) {
      const id = setTimeout(() => {
        unmarkInsuanceQuotes({
          dealerId: currentDealer.id,
          proposalId: currentProposal.proposalId,
        });
      }, 5000);

      setTimeoutId(id);
    } else {
      if (timeoutId) {
        clearTimeout(timeoutId);
        setTimeoutId(null);
      }
    }

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [isOpen]);

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="md">
      <S.Header>
        <S.Title>{dialogTitle}</S.Title>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </S.Header>
      <S.Container>
        <iframe src={iframeUrl} />
      </S.Container>
    </Dialog>
  );
}
