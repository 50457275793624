import React from 'react';

import Typography, { TypographyProps } from '@material-ui/core/Typography';

type propTypes = Omit<TypographyProps, 'color'> &
  Partial<{
    fontSize: string;
    fontFamily: 'Poppins Regular' | 'Poppins Medium' | 'Poppins bold';
    fontWeight: number;
    color: string;
    cursor: string;
    extraStyle: object;
    lineHeight: string;
    lineBreak: 'auto' | 'anywhere' | 'normal' | 'loose';
    whiteSpace:
      | 'normal'
      | 'nowrap'
      | 'pre'
      | 'pre-wrap'
      | 'pre-line'
      | 'break-spaces';
  }>;

const TextComponent = ({
  variant = 'body1',
  children,
  color = '#000',
  fontFamily = 'Poppins Regular',
  fontSize = '16px',
  cursor,
  fontWeight = 400,
  extraStyle = {},
  lineHeight = '24px',
  whiteSpace = 'normal',
  lineBreak = 'auto',
  ...rest
}: propTypes) => {
  return (
    <Typography
      variant={variant}
      style={{
        color: color,
        fontSize: fontSize,
        fontWeight: fontWeight,
        fontFamily: fontFamily,
        cursor: cursor,
        margin: 0,
        padding: 0,
        whiteSpace: whiteSpace,
        lineBreak: lineBreak,
        lineHeight: lineHeight,
        ...extraStyle,
      }}
      {...rest}
    >
      {children}
    </Typography>
  );
};

export default TextComponent;
