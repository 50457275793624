import React, { useEffect, useState } from 'react';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Checkbox from 'bit/mobiauto.web-ui-components.components.checkbox/Checkbox';
import Close from '@material-ui/icons/Close';
import { DialogTitle, IconButton } from '@material-ui/core';
import TextField from 'modules/financing/components/atoms/TextField';
import MatSelect from 'modules/order/components/atoms/MatSelect';
import { OrderStepperState } from 'modules/order/store/reducers';
import {
  OrderProduct,
  OrderProductType,
} from 'modules/order/types/OrderProduct';
import { TypeOfSale } from 'modules/order/types/OrderPayment';
import {
  CheckboxContent,
  CloseDialogButton,
  DialogHeader,
  FormLine,
  SavePaymentButton,
  StyledDialog,
  StyledDialogContent,
  TitleText,
  DialogActionsContainer,
} from './styles';

export type AddProductProps = {
  open: boolean;
  onClose: (product?: OrderProduct) => void;
  orderProductTypes: OrderProductType[];
  editProduct?: OrderProduct;
};

function AddOrderProductDialog({
  open,
  onClose,
  orderProductTypes,
  editProduct,
}: AddProductProps) {
  const {
    paymentData: { typeOfSale, products },
  }: OrderStepperState = useSelector(state => state.order);

  const [productType, setProductType] = useState<
    OrderProductType | undefined
  >();
  const [productValue, setProductValue] = useState<number>();
  const [productDate, setProductDate] = useState(moment().format('YYYY-MM-DD'));
  const [includedOnFinancing, setIncludedOnFinancing] = useState(false);
  const [canBeIncludedOnFinancing, setCanBeIncludedOnFinancing] = useState(
    false
  );

  const getProductTypeOption = (type: OrderProductType) => ({
    value: type.id,
    label: type.name,
    ...type,
  });

  const orderProductTypeOptions = orderProductTypes.map(getProductTypeOption);
  const today = moment().format('YYYY-MM-DD');

  const handleOnChangeProductType = value => {
    setProductType(value);

    setProductValue(value.price || null);
  };
  const handleOnChangeProductValue = (event: NumberFormatValues) => {
    setProductValue(event.floatValue as number);
  };
  const handleOnChangeProductDate = event => {
    setProductDate(event.target.value);
  };
  const handleOnChangeIncludedOnFinancing = event => {
    setIncludedOnFinancing(event.target.checked);
  };

  useEffect(() => {
    if (editProduct) {
      setProductValue(editProduct.value);
      setProductDate(moment(editProduct.date).format('YYYY-MM-DD'));
      setProductType(getProductTypeOption(editProduct.proposalProduct));
      setIncludedOnFinancing(editProduct.includedOnFinancing);
    }
  }, []);

  useEffect(() => {
    setCanBeIncludedOnFinancing(
      Boolean(
        typeOfSale === TypeOfSale.FINANCED &&
          productType?.charged &&
          productType?.canBeFinanced
      )
    );
  }, [productType]);

  const handleOnSave = () => {
    const product: OrderProduct = {
      uuid: editProduct?.uuid,
      id: editProduct?.id,
      proposalProduct: productType as OrderProductType,
      value: productValue as number,
      date: productDate as string,
      includedOnFinancing,
    };
    onClose(product);
  };

  const handleOnClose = () => {
    onClose();
  };

  const haveCurrentProductOnProposal = !!products.find(
    product => product.proposalProduct?.id === productType?.id
  );

  const getHasErrors = () => {
    return (
      haveCurrentProductOnProposal ||
      !productType ||
      !productValue ||
      !productDate
    );
  };

  return (
    <>
      <StyledDialog onClose={handleOnClose} open={open} maxWidth="sm" fullWidth>
        <DialogTitle>
          <DialogHeader>
            <TitleText>Adicionar serviço adicional</TitleText>
            <IconButton aria-label="Close" onClick={handleOnClose}>
              <Close />
            </IconButton>
          </DialogHeader>
        </DialogTitle>
        <StyledDialogContent>
          <FormLine>
            <MatSelect
              label="Tipo de item"
              options={orderProductTypeOptions}
              value={productType}
              onChange={handleOnChangeProductType}
              menuWidth="400px"
              margin="normal"
              fullWidth
              required
              error={haveCurrentProductOnProposal}
              helperText={
                haveCurrentProductOnProposal
                  ? 'Já existe um serviço igual adicionado.'
                  : `Cobrado: ${productType?.charged ? 'Sim' : 'Não'}`
              }
            />
            <NumberFormat
              style={{ marginBottom: 14 }}
              value={productValue}
              onValueChange={handleOnChangeProductValue}
              thousandSeparator="."
              decimalSeparator=","
              allowNegative={false}
              allowLeadingZeros={false}
              placeholder="0"
              prefix="R$ "
              decimalScale={2}
              fixedDecimalScale={true}
              customInput={TextField}
              label="Valor"
              required
              disabled={!productType?.changePrice}
            />
          </FormLine>
          <FormLine>
            <TextField
              value={productDate}
              onChange={handleOnChangeProductDate}
              label="Data"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{ min: today }}
              required
            />
            <CheckboxContent>
              {canBeIncludedOnFinancing && (
                <Checkbox
                  checked={includedOnFinancing}
                  onChange={handleOnChangeIncludedOnFinancing}
                  label="Adicionar ao financiamento"
                />
              )}
            </CheckboxContent>
          </FormLine>
        </StyledDialogContent>
        <DialogActionsContainer>
          <CloseDialogButton onClick={handleOnClose}>
            Cancelar
          </CloseDialogButton>
          <SavePaymentButton
            variant="contained"
            disabled={getHasErrors()}
            onClick={handleOnSave}
          >
            Salvar
          </SavePaymentButton>
        </DialogActionsContainer>
      </StyledDialog>
    </>
  );
}

export default AddOrderProductDialog;
