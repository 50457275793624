import React from 'react';
import moment from 'moment';

import Avatar from '@material-ui/core/Avatar';

import TextComponent from 'modules/mobiOne/components/Text/TextComponent';
import ListIcon from '@material-ui/icons/List';

import * as S from './styles';
import * as Ms from 'components/ProposalMessage/components/Message/styles';

import formatTime from 'helpers/formatTime';

import { ReactComponent as LegsLeftChatBubble } from 'assets/legs-left-chat-bubble.svg';

import Wrapper from 'components/Wrapper';
import getImage from 'components/getImage';

export type TemplateMessage = {
  message: string;
  imageUrl?: any;
  footerText?: string;
  headerText?: string;
  listButton?: string;
  buttons?: {
    id?: string | number;
    label: string;
  }[];
};

export type TextBubbleType = {
  message: string;
  image?: string | { imageId?: number; imageVCode?: string };
  footerText?: string;
  headerText?: string;
};

const PreviewMessage = ({
  templateMessage,
  contentType,
  fullWidth = true,
}: {
  templateMessage: TemplateMessage;
  contentType?: string;
  fullWidth?: boolean;
}) => {
  const todayDate = moment()
    .toDate()
    .getTime();

  const messageDate = `${moment(todayDate).format('DD/MM')} - ${formatTime(
    new Date(todayDate)
  )}`;

  const textBubble = ({
    message,
    image,
    footerText,
    headerText,
  }: TextBubbleType) => {
    return (
      <Ms.ContainerMessage style={{ width: '100%' }} isPF={true}>
        <Ms.Box style={{ maxWidth: '90%' }} isPF={false}>
          {image && (
            <img
              src={
                typeof image === 'string'
                  ? image
                  : getImage({
                      imageId: image?.imageId,
                      imageVcode: image?.imageVCode,
                    })
              }
              width="100%"
              loading="lazy"
            />
          )}
          {headerText && (
            <TextComponent fontSize="18px" fontWeight={600} color="#fff">
              {headerText}
            </TextComponent>
          )}
          <TextComponent
            fontSize="14px"
            lineHeight="20px"
            lineBreak="anywhere"
            whiteSpace="pre-wrap"
            color="#fff"
            extraStyle={{ marginBottom: 8 }}
          >
            {message}
          </TextComponent>
          {footerText && (
            <TextComponent
              fontSize="14px"
              whiteSpace="pre-wrap"
              color="#fff"
              lineHeight="28px"
              extraStyle={{ marginBottom: 8, fontStyle: 'italic' }}
            >
              {footerText}
            </TextComponent>
          )}
          <Ms.FooterMessage>{messageDate}</Ms.FooterMessage>
        </Ms.Box>
        <LegsLeftChatBubble />
      </Ms.ContainerMessage>
    );
  };

  const getMessageTemplate = () => {
    switch (contentType) {
      case 'BUTTON':
        return (
          <Wrapper flex direction="column" alignSelf="end" gap="sm">
            {textBubble({ message: templateMessage?.message })}
            {templateMessage?.buttons?.length && (
              <Wrapper flex wrap="wrap" gap="sm" width="100%">
                {templateMessage?.buttons?.map(
                  (btn, index) =>
                    btn.label && (
                      <S.OptionsButton disabled={true} key={`${index}-button`}>
                        {btn?.label}
                      </S.OptionsButton>
                    )
                )}
              </Wrapper>
            )}
          </Wrapper>
        );
      case 'TEXT':
        return (
          <Wrapper flex direction="column" alignSelf="end" gap="sm">
            {textBubble({ message: templateMessage?.message })}
          </Wrapper>
        );
      case 'IMAGE':
        return (
          <Wrapper flex direction="column" alignSelf="end" gap="sm">
            {textBubble({
              message: templateMessage?.message,
              image: templateMessage?.imageUrl,
            })}
          </Wrapper>
        );
      case 'WHATSAPP_CARD':
        return (
          <Wrapper flex direction="column" alignSelf="end" gap="sm">
            {textBubble({
              message: templateMessage?.message,
              image: templateMessage?.imageUrl,
              footerText: templateMessage?.footerText,
              headerText: templateMessage?.headerText,
            })}
            {templateMessage?.buttons?.length && (
              <Wrapper flex wrap="wrap" width="100%" gap="sm">
                {templateMessage?.buttons?.map(
                  (btn, index) =>
                    btn.label && (
                      <S.OptionsButton disabled={true} key={`${index}-button`}>
                        {btn?.label}
                      </S.OptionsButton>
                    )
                )}
              </Wrapper>
            )}
          </Wrapper>
        );
      case 'LIST':
        return (
          <Wrapper flex direction="column" alignSelf="end" gap="sm">
            {textBubble({
              message: templateMessage?.message,
            })}
            {templateMessage?.listButton && (
              <S.OptionsButton disabled={true}>
                <ListIcon />
                {templateMessage?.listButton}
              </S.OptionsButton>
            )}
          </Wrapper>
        );
      default:
        return textBubble({ message: 'Tipo de Template Inválido.' });
    }
  };

  return (
    <S.Container
      style={{
        height: '100%',
        maxWidth: 350,
        width: fullWidth ? '100%' : 'auto',
      }}
    >
      <S.Header>
        <Avatar>JO</Avatar>
        <S.UserInfo>
          <TextComponent
            fontSize="16px"
            fontWeight={500}
            fontFamily="Poppins Medium"
            color="text.primary"
          >
            João Oliver
          </TextComponent>
          <TextComponent
            fontSize="12px"
            fontWeight={400}
            fontFamily="Poppins Regular"
            color="#bbb"
          >
            {moment(todayDate).format('DD/MMM')}
          </TextComponent>
        </S.UserInfo>
      </S.Header>

      <Ms.Container>{getMessageTemplate()}</Ms.Container>
    </S.Container>
  );
};

export default PreviewMessage;
