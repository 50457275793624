import React, { useEffect } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { ReactComponent as WatsappIcon } from 'assets/whatsapp.svg';
import columns from 'constants/columns';
import {
  WrapperContainer,
  CircleQuantityMessages,
  WhatsappWaitingDealer,
} from './styles';

const ColumnFilterComponent = ({
  someUnreadMessages,
  setSomeUnreadMessages,
  tasks,
  statusKey,
}) => {
  const [selectValue, setSelectValue] = React.useState(someUnreadMessages);

  const numberOfUnreadMessagesCentral = tasks
    ?.filter(
      el =>
        ![
          columns.QUALIFIED.id,
          columns.DISQUALIFIED.id,
          columns.NOT_CONTACTED.id,
          columns.UNATTENDED.id,
          columns.SUCCESS.id,
          columns.FAILURE.id,
        ].includes(el.status)
    )
    ?.filter(el =>
      [
        columns.NEW.id,
        columns.PROSPECT.id,
        columns.IN_PROGRESS.id,
        columns.SCHEDULED.id,
      ].includes(el.salesAssistantStatus)
    )
    ?.filter(el => el.whatsappWaitingDealerResponse)?.length;

  const numberOfUnreadMessagesSearchAndRescue = tasks
    ?.filter(
      el =>
        ![
          columns.QUALIFIED.id,
          columns.DISQUALIFIED.id,
          columns.NOT_CONTACTED.id,
          columns.UNATTENDED.id,
          columns.SUCCESS.id,
          columns.FAILURE.id,
        ].includes(el.searchAndRescueStatus)
    )
    ?.filter(el => el.whatsappWaitingDealerResponse)?.length;

  const numberOfUnreadMessages =
    statusKey === 'searchAndRescueStatus'
      ? numberOfUnreadMessagesSearchAndRescue
      : numberOfUnreadMessagesCentral;

  useEffect(() => {
    setSomeUnreadMessages(selectValue);
  }, [selectValue]);

  return numberOfUnreadMessages ? (
    <WrapperContainer
      onClick={() => setSelectValue(!selectValue)}
      selectValue={selectValue}
    >
      <Tooltip
        title={
          numberOfUnreadMessages &&
          'Possui mensagens não lidas no canal do Whatsapp'
        }
      >
        {numberOfUnreadMessages && (
          <WhatsappWaitingDealer>
            <WatsappIcon />
            <CircleQuantityMessages>
              {numberOfUnreadMessages}
            </CircleQuantityMessages>
          </WhatsappWaitingDealer>
        )}
      </Tooltip>
    </WrapperContainer>
  ) : null;
};

export default ColumnFilterComponent;
