interface CreateInsuranceProposalParams {
  clientEmail?: string;
  clientUserCpfCnpj?: string;
  clientUserName?: string;
  clientUserPhone?: string;
  clientCivilStatus?: string;
  clientGender?: string;
  clientBirthDate?: string;
  clientZipCode?: string;
  modelOrModelName?: string;
  modelYear?: number;
  plate?: string;
  productionYear?: number;
  proposalId?: number;
  vin?: string;
  todayMoreThreeDays1404?: string;
  todayMoreThreeDays1405?: string;
}

enum InsuranceProposalParams {
  clientEmail = 'Padrao2000_E_mail',
  clientUserCpfCnpj = 'Padrao2000_Cgc_cpf',
  clientUserName = 'Padrao2000_Nome',
  clientUserPhone = 'Padrao2000_Telefone_celMod1',

  clientCivilStatus = 'Padrao2000_Estado_civil',
  clientGender = 'Padrao2000_Sexo',
  clientBirthDate = 'Padrao2000_Datanascimento',
  clientZipCode = 'Padrao2000_Cobertura1851',

  modelOrModelName = 'Padrao2000_Modelo',
  modelYear = 'Padrao2000_Anomodelo',
  plate = 'Padrao2000_PlacaMod3',
  productionYear = 'Padrao2000_Anofabricacao',
  proposalId = 'Padrao2000_CotacaoExterna',
  vin = 'Padrao2000_ChassiMod3',
  todayMoreThreeDays1404 = 'Padrao2000_Cobertura1404',
  todayMoreThreeDays1405 = 'Padrao2000_Cobertura1405',
}

export const getParamsToCreateInsuranceProposal = (
  params: CreateInsuranceProposalParams
) => {
  const cleanedParams = Object.fromEntries(
    Object.entries(params).filter(([, value]) => {
      return value !== undefined && String(value).trim().length > 0;
    })
  );

  const filteredParams: Record<string, string> = {};

  Object.entries(InsuranceProposalParams).forEach(([key, value]) => {
    if (key in cleanedParams) filteredParams[value] = cleanedParams[key];
  });

  const formattedParams = Object.entries(filteredParams)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  return formattedParams;
};

export const buildCivilStatusToInsurance = {
  NOT_INFORMED: {
    label: 'Não informado',
    value: '',
  },
  SINGLE: {
    label: 'Solteiro',
    value: 'S',
  },
  MARRIED: {
    label: 'Casado',
    value: 'C',
  },
  SEPARATED: {
    label: 'Separado',
    value: 'S',
  },
  DIVORCED: {
    label: 'Divorciado',
    value: 'D',
  },
  WIDOW: {
    label: 'Viúvo',
    value: 'V',
  },
  STABLE_UNION: {
    label: 'União Estável',
    value: 'U',
  },
};
