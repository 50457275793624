import ProposalsListServices from 'modules/resale/services/ProposalsListServices';
import ProposalListCorporativeSaleServices from 'modules/resale/services/ProposalListCorporativeSaleServices';
import NegotiationServices from 'modules/resale/services/NegotiationServices';
import NegotiationCorporativeSaleServices from 'modules/resale/services/NegotiationCorporativeSaleServices';
import getEnv from 'helpers/envVars';
export const PORTAL_COLABORADOR_BANCOPAN_DEALER_ID = 83561;
export const PORTAL_COLABORADOR_BANCOSAFRA_DEALER_ID = getEnv('CORPORATIVE_SALE_PAN_ID');
export const ID_MOVIDA = 33686;

console.log('PORTAL_COLABORADOR_BANCOSAFRA_DEALER_ID', PORTAL_COLABORADOR_BANCOSAFRA_DEALER_ID);

export const CorportativeSaleIds = [PORTAL_COLABORADOR_BANCOPAN_DEALER_ID, Number(PORTAL_COLABORADOR_BANCOSAFRA_DEALER_ID)];

export function ProposalServicesToggleEndpoints(dealerId) {
  return CorportativeSaleIds.includes(Number(dealerId))
    ? ProposalListCorporativeSaleServices
    : ProposalsListServices;
}

export function NegotiationServicesToggleEndpoints(dealerId) {
  return CorportativeSaleIds.includes(Number(dealerId))
    ? NegotiationCorporativeSaleServices
    : NegotiationServices;
}

export const ownTheProductBuyItNow = ({ dealerId }) => {
  return PORTAL_COLABORADOR_BANCOPAN_DEALER_ID !== Number(dealerId);
};
