import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@material-ui/core';
import TextField from 'modules/financing/components/atoms/TextField';
import dealerServices from 'services/dealerServices';
import TagChip, { CheckedTagsProps } from 'components/atoms/TagChip/TagChip';
import InputColor from 'components/InputColor';
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';
import Wrapper from 'components/Wrapper';
import GenericDialog from 'components/GenericDialog';
import ThemeButton from 'components/atoms/ThemeButton';
import OutlinedButton from 'components/atoms/OutlinedButton';

export interface ModalCreateTagsProps {
  open: boolean;
  handleCloseModal: () => void;
  tag?: CheckedTagsProps;
  getAllTags?: () => void;
  proposalDealerId: number | string;
}

const ModalEditTags = ({
  open,
  handleCloseModal,
  tag,
  getAllTags,
  proposalDealerId,
}: ModalCreateTagsProps) => {
  const dispatch = useDispatch();
  const [query, setQuery] = useState(tag?.name);
  const [color, setColor] = useState(tag?.color);

  const onChangeQuery = event => {
    setQuery(event.target.value);
  };

  const saveEditTag = async objTag => {
    try {
      const response: any = await dealerServices.updateTag(
        proposalDealerId,
        tag?.id,
        objTag
      );
      if (!response?.success && typeof response?.data === 'string') {
        dispatch(UIActionCreators.snackbarManagement('error', response?.data));
        return;
      }

      if (response.success) {
        dispatch(
          UIActionCreators.snackbarManagement(
            'success',
            'Alteração salva com sucesso.'
          )
        );
        handleCloseModal();
      }
    } catch {
      dispatch(
        UIActionCreators.snackbarManagement('error', 'Ocorreu um erro.')
      );
    } finally {
      getAllTags();
    }
  };

  return (
    <GenericDialog
      open={open}
      onClose={handleCloseModal}
      title={'Editar tag'}
      maxWidth="sm"
      DialogAction={() => (
        <>
          <Wrapper
            flex
            justify="end"
            direction={'row'}
            gap={16}
            style={{ width: '100%' }}
          >
            <OutlinedButton onClick={handleCloseModal}>Cancelar</OutlinedButton>
            <ThemeButton
              onClick={() =>
                saveEditTag({ name: query, color: color, id: tag?.id })
              }
              disabled={!query.length}
            >
              Salvar
            </ThemeButton>
          </Wrapper>
        </>
      )}
    >
      <Box component="div">
        <Box
          component="div"
          display="flex"
          height="55px"
          bgcolor="#EBEBEB"
          marginBottom="16px"
          justifyContent="center"
          alignItems="center"
        >
          {!!query.length && (
            <TagChip
              label={query}
              tagId={null}
              color={color}
              onClickCancel={() => null}
            />
          )}
        </Box>

        <TextField
          name="query"
          label="Texto tag"
          value={query}
          onChange={onChangeQuery}
          inputProps={{ maxLength: 26 }}
          helperText={
            query?.length < 26 ? (
              `${query?.length || 0}/26`
            ) : (
              <Box color="#F44336">
                {query?.length}/26 Limite de 26 caracteres atingido!
              </Box>
            )
          }
          mini
        />

        <Box
          component="div"
          display="flex"
          flexDirection="row"
          alignItems="center"
          margin="10px"
        >
          <Box component="div" display="flex" color="#000000">
            Cor da Tag
          </Box>
          <InputColor
            value={color}
            onChange={value => {
              setColor(value);
            }}
            border="none"
          />
        </Box>
      </Box>
    </GenericDialog>
  );
};
export default ModalEditTags;
