function isValidValue(value: unknown): boolean {
  return value !== undefined && value !== null;
}

function addArrayToSearchParams(
  searchParams: URLSearchParams,
  key: string,
  array: (string | number | boolean)[]
): void {
  const filteredArray = array.filter(isValidValue);

  filteredArray.forEach(item => {
    searchParams.append(key, item.toString());
  });
}

function appendValidValueToSearchParams(
  searchParams: URLSearchParams,
  key: string,
  value: string | number | boolean | Array<string | number | boolean>
): void {
  if (isValidValue(value)) searchParams.append(key, value.toString());
}

export function buildQueryParams(
  params: Record<
    string,
    string | number | boolean | Array<string | number | boolean>
  >,
  changeSpaceFromPlusToPercent = false
): string {
  const searchParams = new URLSearchParams();

  try {
    Object.keys(params).forEach(key => {
      const value = params[key];
      if (Array.isArray(value)) {
        addArrayToSearchParams(searchParams, key, value);
      } else {
        appendValidValueToSearchParams(searchParams, key, value);
      }
    });
  } catch (error) {
    throw new Error(
      'Falha ao construir parâmetros de consulta. Verifique os valores fornecidos.'
    );
  }

  if (changeSpaceFromPlusToPercent) {
    return searchParams.toString().replace(/\+/g, '%20');
  }

  return searchParams.toString();
}
