/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';

import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Wrapper from 'components/Wrapper';

import useEvaluation from '../../hooks/useEvaluation';

import Form from './Form';
import Vehicle from './Vehicle';

import * as S from './styles';
import { FieldType } from './PricingVehicleTypes';
import { usePricingVehicleContext } from './PricingVehicleContext';
import useSnackBar from 'hooks/useSnackBar';
import { Pricing } from 'pages/chats/components/SideBarComponents/Evaluation/services/dto';
import EvaluationsInProgressCard from 'pages/chats/components/SideBarComponents/Evaluation/components/EvaluationsInProgressCard/index';
import { EvaluationInProgress } from 'pages/chats/components/SideBarComponents/Evaluation/sideBarEvaluationTypes';
import {
  checkIfThereIsEvaluationByPlate,
  followEvaluation,
} from 'pages/chats/components/SideBarComponents/Evaluation/services/api';
import { useQuery, useMutation } from 'react-query';

function CustomIcon({
  status,
  stepIndex,
}: {
  status: string;
  stepIndex: number;
}) {
  if (status === 'completed') return <CheckCircleOutlinedIcon />;
  return <span>{stepIndex + 1}</span>;
}

const STEPS = ['Dados do veículo', 'Precificação'];
const COLOR_NOT_INFORMED_ID = 28;

const PricingVehicleComponent = ({
  dealerId,
  loading,
  proposalId,
  modal,
  dealerBelongsToSagaGroup,
  handleCloseModal,
  handleEvaluateLater,
  handleOnChangeValue,
  handleClickOnStartEvaluation,
}: {
  loading: boolean;
  dealerId: number;
  proposalId: number;
  modal: boolean;
  handleCloseModal: () => void;
  handleConfirm: (pricing: Pricing[]) => void;
  handleEvaluateLater: (pricing: Pricing[]) => void;
  handleOnChangeValue: ({
    field,
    newValue,
  }: {
    field: FieldType;
    newValue: any;
  }) => void;
  handleClickOnStartEvaluation: () => void;
  dealerBelongsToSagaGroup: boolean;
}) => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const [pricing, setPricing] = useState<Pricing[]>([]);
  const { deal, pricingVehicleFormData } = usePricingVehicleContext();
  const snackbar = useSnackBar();

  const [enableCheckPlate, setEnableCheckPlate] = React.useState(false);

  const {
    dataReferencePrices,
    getReferencePrices,
    loadingGetReferencePrices,
    isLoading,
  } = useEvaluation({
    dealerId,
    proposalId,
  });

  const isLoadingControl = isLoading || loading;

  const isLastStep = activeStep === STEPS.length - 1;

  const handleNext = () => {
    if (pricingVehicleFormData.isValid) {
      setEnableCheckPlate(true);

      return setActiveStep(prevActiveStep => prevActiveStep + 1);
    }

    snackbar.error('Preencha todos os campos obrigatórios');
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleGetReferencePrices = () => {
    const { values } = pricingVehicleFormData;

    getReferencePrices(
      {
        dealerId: dealerId,
        colorId: values?.color?.value || COLOR_NOT_INFORMED_ID,
        fuelId: values?.fuel?.value,
        km: values?.km,
        modelYear: values?.modelYear?.value,
        plate: values?.plate,
        proposalId: proposalId,
        provider: dealerBelongsToSagaGroup ? 'PRIMEIRA_MAO' : 'MOBIAUTO',
        trimId: values?.trim?.value,
      },
      {
        onSuccess: (response: any) => {
          if (response.success) {
            setPricing(response.data as Pricing[]);
          }
        },
      }
    );
  };

  const { data: checkIfExistsEvaluationByPlateData } = useQuery({
    queryKey: 'checkIfExistsEvaluationByPlate',
    queryFn: async () =>
      checkIfThereIsEvaluationByPlate({
        dealerId,
        plate: pricingVehicleFormData?.values?.plate,
      }),
    onSettled: (response: any) => {
      if (response?.success) {
        if (response?.data?.canRequestEvaluation) {
          return handleGetReferencePrices();
        }

        return;
      }

      snackbar.error('Erro ao validar se já existe avaliação');
    },
    enabled: enableCheckPlate,
    refetchOnWindowFocus: false,
  });

  const getCancelButtonProps = () => {
    return {
      label: activeStep === 0 ? 'Cancelar' : 'Voltar',
      action: () => {
        setEnableCheckPlate(false);

        if (activeStep === 0) {
          return handleCloseModal();
        }

        return handleBack();
      },
    };
  };

  const getConfirmButtonProps = () => {
    return {
      label: isLastStep ? 'Solicitar' : 'Avançar',
      action: () => {
        if (isLastStep) {
          if (
            checkIfExistsEvaluationByPlateData?.data?.exists &&
            checkIfExistsEvaluationByPlateData?.data?.canRequestEvaluation
          ) {
            return handleEvaluateLater(pricing);
          }

          return handleClickOnStartEvaluation();
        }
        return handleNext();
      },
    };
  };

  const getStepStatus = (step: number) => {
    const isActived = step === activeStep;
    const isCompleted = step < activeStep;

    if (isCompleted) return 'completed';
    if (isActived) return 'actived';
    return 'incompleted';
  };

  const btnCancel = getCancelButtonProps();
  const btnConfirm = getConfirmButtonProps();

  const { mutate: insertEvaluationInProgressOnLeadMutate } = useMutation({
    mutationKey: 'insertEvaluationInProgressOnLead',
    mutationFn: async () => {
      try {
        await followEvaluation({
          dealerId,
          evaluationId:
            checkIfExistsEvaluationByPlateData?.data?.evaluations?.[0].id,
          proposalId,
        });
      } catch (e) {
        snackbar.error('Erro ao acompanhar avaliação');
      }
    },
  });

  useEffect(() => {
    setPricing([]);
  }, []);

  React.useEffect(() => {
    if (
      pricing.length > 0 &&
      activeStep === 1 &&
      (!checkIfExistsEvaluationByPlateData?.data?.exists ||
        (checkIfExistsEvaluationByPlateData?.data?.exists &&
          checkIfExistsEvaluationByPlateData?.data?.canRequestEvaluation))
    ) {
      handleEvaluateLater(pricing);
    }
  }, [pricing, checkIfExistsEvaluationByPlateData?.data?.exists]);

  return (
    <S.Dialog
      maxWidth="md"
      fullWidth
      open={modal}
      onClose={() => {
        setEnableCheckPlate(false);
        handleCloseModal();
      }}
    >
      <DialogTitle>
        <S.Header>
          <b className="title">Solicitar avaliação</b>

          <IconButton
            onClick={() => {
              setEnableCheckPlate(false);
              handleCloseModal();
            }}
            size="small"
          >
            <CloseIcon />
          </IconButton>
        </S.Header>
        <S.Stepper activeStep={activeStep} alternativeLabel>
          {STEPS.map((label, index) => {
            const status = getStepStatus(index);
            return (
              <Step key={label} className={status}>
                <StepLabel
                  StepIconComponent={() => (
                    <CustomIcon status={status} stepIndex={index} />
                  )}
                >
                  {label}
                </StepLabel>
              </Step>
            );
          })}
        </S.Stepper>
      </DialogTitle>
      <DialogContent style={{ padding: '8px 0' }}>
        {activeStep === 0 && (
          <Form
            loading={loading}
            dealerBelongsToSagaGroup={dealerBelongsToSagaGroup}
            handleOnChangeValue={handleOnChangeValue}
          />
        )}
        {activeStep === 1 && (
          <Wrapper flex direction="column" gap="md" width="100%">
            {checkIfExistsEvaluationByPlateData?.data?.exists &&
              !checkIfExistsEvaluationByPlateData?.data
                ?.canRequestEvaluation && (
                <EvaluationsInProgressCard
                  evaluationsInProgress={
                    (checkIfExistsEvaluationByPlateData?.data
                      ?.evaluations as EvaluationInProgress[]) || []
                  }
                />
              )}
            <Vehicle
              deal={deal}
              referencePrices={dataReferencePrices}
              evaluationInProgressReferencePrices={
                checkIfExistsEvaluationByPlateData?.data?.evaluations?.[0]
                  ?.vehiclePricing
              }
              dealerBelongsToSagaGroup={dealerBelongsToSagaGroup}
              isLoading={loadingGetReferencePrices}
            />
          </Wrapper>
        )}
      </DialogContent>
      <DialogActions>
        {isLoadingControl ? (
          <CircularProgress style={{ width: 26, height: 26 }} />
        ) : (
          <>
            <S.CancelButton
              onClick={() => btnCancel.action()}
              disabled={isLoadingControl}
            >
              {btnCancel.label}
            </S.CancelButton>
            {isLastStep && (
              <S.SaveAndCloseButton
                onClick={() => {
                  setEnableCheckPlate(false);

                  if (
                    !checkIfExistsEvaluationByPlateData?.data
                      ?.canRequestEvaluation
                  ) {
                    insertEvaluationInProgressOnLeadMutate();

                    return handleCloseModal();
                  }

                  handleCloseModal();
                }}
                disabled={isLoadingControl}
              >
                {checkIfExistsEvaluationByPlateData?.data?.exists &&
                !checkIfExistsEvaluationByPlateData?.data?.canRequestEvaluation
                  ? 'Acompanhar avaliação'
                  : 'Avaliar depois'}
              </S.SaveAndCloseButton>
            )}
            <S.ConfirmButton
              onClick={() => {
                setEnableCheckPlate(false);

                pricingVehicleFormData.handleSubmit();

                btnConfirm.action();
              }}
              disabled={
                isLastStep &&
                !checkIfExistsEvaluationByPlateData?.data?.canRequestEvaluation
              }
            >
              {btnConfirm.label}
            </S.ConfirmButton>
          </>
        )}
      </DialogActions>
    </S.Dialog>
  );
};

export default React.memo(PricingVehicleComponent);
