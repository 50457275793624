import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as StepArrowActive } from 'assets/step-arrow-active.svg';
import { ReactComponent as StepArrowDefault } from 'assets/step-arrow-default.svg';
import { ReactComponent as FirstStepArrowActive } from 'assets/first-step-arrow-active.svg';

import ProposalStatusTip from 'components/ProposalStatusTip/ProposalStatusTip';

import { useChatContext } from 'pages/chats/ChatsContext';

import ProposalServices from 'services/proposalServices';

import { Creators as proposalCreators } from 'modules/resale/store/ducks/proposal';

import columns from 'constants/columns';
import { highAccessUserTypes } from 'constants/userAccess';

import lazyRetry from 'helpers/lazyRetry';
import { checkCallCenterOsSearchAndRescueRoute } from 'helpers/checkCallcenterOrSearchAndRescue';

import { usePermissions } from 'hooks/usePermissions';

import ProposalRating from '../../components/ProposalRating';

import ConfirmStatusDialog from '../ConfirmStatusDialog';
import ArchiveProposal from '../SubHeader/components/ArchiveProposal';

import { StepArrowButton, LabelStepArrowButton, Container } from './styles';
import useSnackBar from 'hooks/useSnackBar';

const AddSchedule = React.lazy(() =>
  lazyRetry(() => import('components/AddSchedule'))
);
const AddAnnotation = React.lazy(() =>
  lazyRetry(() => import('components/AddAnnotation'))
);
const finishedTypes = [
  'WITH_ORDER',
  'FINISHED_WITH_SOLD',
  'FINISHED_AND_CONCLUDED',
  'FINISHED_BUT_NOT_CONCLUDED',
  'FINISHED_BY_TIME',
  'FINISHED_WITHOUT_SOLD',
];

const SubHeader = ({ currentProposalStatus }) => {
  const dispatch = useDispatch();

  const {
    currentProposal,
    getCurrentProposal,
    currentUserType,
  }: any = useChatContext();

  const { mustHaveProposalAnnotation } = useSelector(
    state => state.dealerStore.currentDealer
  );

  const [proposalStatus, setProposalStatus] = useState(null);
  const [openAnnotation, setOpenAnnotation] = useState(false);
  const [openScheduling, setOpenScheduling] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const { hasPermission } = usePermissions();
  const { handleSnackBarError, handleSnackBarWarning } = useSnackBar();

  const canArchiveProposals = checkCallCenterOsSearchAndRescueRoute()
    ? highAccessUserTypes.includes(currentUserType)
    : hasPermission('PROPOSAL_ARCHIVE');

  const alterStatusProposal = async (
    status,
    extraData = { orderCpfCnpj: '', orderId: '', statusDetailId: '' }
  ) => {
    try {
      if (status) {
        const response: any = await ProposalServices.changeStatusProposal(
          currentProposal.dealerId,
          currentProposal.id,
          status,
          extraData
        );
        if (response.success) {
          if (status === 'PROPOSAL_ORDER_OEM') {
            dispatch(
              proposalCreators.setProposalModalOpen(true, {
                dealerId: currentProposal.dealerId,
                proposalId: currentProposal.id,
              })
            );
          }
          return true;
        }

        const errorMessage = response?.data;
        const triggerWarningMessage = status === 'FINISHED_BUT_NOT_CONCLUDED';

        if (triggerWarningMessage) {
          handleSnackBarWarning(errorMessage);
          return false;
        }

        handleSnackBarError(errorMessage);
        throw new Error(errorMessage);
      }
    } catch (e) {
      handleSnackBarError(`${e}`);
      return false;
    } finally {
      getCurrentProposal(currentProposal.dealerId, currentProposal.id);
    }
  };

  const hasToShowProposalStatus = () => {
    return (
      currentProposalStatus &&
      ![
        columns.NEW.id,
        columns.IN_NEGOTIATION.id,
        columns.SCHEDULED.id,
      ].includes(currentProposalStatus)
    );
  };

  useEffect(() => {
    setProposalStatus(currentProposalStatus);
  }, [currentProposalStatus]);

  return (
    <Container>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          height: 51,
        }}
      >
        {/* NEW */}
        <StepArrowButton>
          <FirstStepArrowActive />
          <LabelStepArrowButton firstStep isActive>
            Novo
          </LabelStepArrowButton>
        </StepArrowButton>
        {/* IN_NEGOTIATION */}
        <StepArrowButton
          onClick={() =>
            finishedTypes.includes(proposalStatus)
              ? null
              : mustHaveProposalAnnotation
              ? setOpenAnnotation(true)
              : alterStatusProposal(columns.IN_NEGOTIATION.id)
          }
        >
          {proposalStatus !== 'NEW' ? (
            <StepArrowActive />
          ) : (
            <StepArrowDefault />
          )}
          <LabelStepArrowButton
            isActive={!['NEW'].includes(proposalStatus) ? true : false}
          >
            Em negociação
          </LabelStepArrowButton>
        </StepArrowButton>
        {/* SCHEDULES */}
        <StepArrowButton
          onClick={() =>
            finishedTypes.includes(proposalStatus)
              ? null
              : setOpenScheduling(true)
          }
        >
          {!['NEW', 'IN_NEGOTIATION'].includes(proposalStatus) ? (
            <StepArrowActive />
          ) : (
            <StepArrowDefault />
          )}
          <LabelStepArrowButton
            isActive={
              !['NEW', 'IN_NEGOTIATION'].includes(proposalStatus) ? true : false
            }
          >
            Agendamento
          </LabelStepArrowButton>
        </StepArrowButton>
        {/* CONCLUID */}
        <StepArrowButton
          onClick={() =>
            finishedTypes.includes(proposalStatus)
              ? null
              : setOpenConfirmDialog(true)
          }
        >
          {finishedTypes.includes(proposalStatus) ? (
            <StepArrowActive />
          ) : (
            <StepArrowDefault />
          )}
          <LabelStepArrowButton
            isActive={finishedTypes.includes(proposalStatus) ? true : false}
          >
            Concluído
          </LabelStepArrowButton>
        </StepArrowButton>
        <div style={{ width: 8 }} />
        {hasToShowProposalStatus() && (
          <ProposalStatusTip
            status={currentProposalStatus}
            statusDetail={currentProposal.statusDetailName}
          />
        )}
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <ProposalRating />
        {canArchiveProposals && (
          <>
            <div
              style={{
                borderRight: '1px solid #b6b6b6',
                margin: '0px 8px',
                height: '60%',
              }}
            ></div>
            <ArchiveProposal />
          </>
        )}
      </div>
      {openAnnotation && (
        <AddAnnotation
          open={openAnnotation}
          handleClose={() => setOpenAnnotation(false)}
          currentProposal={{
            dealerId: currentProposal.dealerId,
            id: currentProposal.id,
          }}
          callbackOnSuccess={() => {
            alterStatusProposal(columns.IN_NEGOTIATION.id);
            setOpenAnnotation(false);
          }}
          dateIsRequired
        />
      )}
      {openScheduling && (
        <AddSchedule
          open={openScheduling}
          handleClose={() => setOpenScheduling(false)}
          currentProposal={{
            dealerId: currentProposal.dealerId,
            id: currentProposal.id,
          }}
          callbackOnSuccess={() => {
            alterStatusProposal(columns.SCHEDULED.id);
            setOpenScheduling(false);
          }}
        />
      )}
      {openConfirmDialog && (
        <ConfirmStatusDialog
          isOpen={openConfirmDialog}
          onClose={() => setOpenConfirmDialog(false)}
          confirmStatus={alterStatusProposal}
          draggableItemId={currentProposal.dealerId}
          departmentId={currentProposal?.department?.id}
          clientUserCpfCnpj={currentProposal?.clientUserCpfCnpj}
          currentProposalId={currentProposal.id}
        />
      )}
    </Container>
  );
};

export default SubHeader;
