import styled from 'styled-components';

export const Container = styled.div`
  height: calc(100vh - 280px);
  overflow-y: auto;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
`;

export const ContainerIframeModal = styled.div`
  overflow-x: hidden;
`;
