import React from 'react';

const Img = ({ src, alt, onLoad, ...props }) => (
  <img src={src} alt={alt} onLoad={onLoad} {...props} />
);
const LazyImg = ({ src, alt, className, onLoad, ...props }) => (
  <img
    data-src={src}
    className={`${className} lazyload`}
    alt={alt}
    onLoad={onLoad}
    {...props}
  />
);
export { Img, LazyImg };
