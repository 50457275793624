import { defaultSelectObject } from 'constants/defaultSelect';
import { action, decorate, observable } from 'mobx';
import remotedev from 'mobx-remotedev';
import InventoryService from 'services/inventoryServices';
import MechanicalWarrantyServices from 'services/mechanicalWarrantyServices';
import SearchService from 'services/searchServices';
const _defaultInitialState = {
  vehicleType: [],
  make: [],
  trim: [],
  engine: [],
  years: [],
  cor: [],
  porta: [],
  cambio: [],
  combustivel: [],
  features: [],
  vehicleConditionals: [],
  fuels: [],
  transmissions: [],
  colors: [],
  hasFetched: false,
  hasFailed: false,
  isLoading: false,
  states: [],
  cities: [],
  locationByzipCode: {},
  dataByPlate: {},
  vehicleTypes: [],
  isEligibility: false,
  hasMechanicalWarranty: false,
  downPayment: 0,
  remainingPayment: 0,
  totalPrice: 0,
};

class SearchStore {
  constructor(initialState) {
    this.setInitialState(_defaultInitialState);
    this.rootStore = initialState;
  }

  setInitialState = ({
    vehicleType,
    make,
    trim,
    engine,
    years,
    features,
    colors,
    transmissions,
    fuels,
    vehicleConditionals,
    hasFetched,
    hasFailed,
    isLoading,
    states,
    cities,
    locationByzipCode,
    dataByPlate,
    vehicleTypes,
    isEligibility,
    hasMechanicalWarranty,
    downPayment,
    remainingPayment,
    totalPrice,
  }) => {
    this.vehicleType = vehicleType;
    this.make = make;
    this.trim = trim;
    this.engine = engine;
    this.years = years;
    this.features = features;
    this.colors = colors;
    this.transmissions = transmissions;
    this.fuels = fuels;
    this.vehicleConditionals = vehicleConditionals;
    this.hasFetched = hasFetched;
    this.hasFailed = hasFailed;
    this.isLoading = isLoading;
    this.states = states;
    this.cities = cities;
    this.locationByzipCode = locationByzipCode;
    this.dataByPlate = dataByPlate;
    this.vehicleTypes = vehicleTypes;
    this.isEligibility = isEligibility;
    this.hasMechanicalWarranty = hasMechanicalWarranty;
    this.downPayment = downPayment;
    this.remainingPayment = remainingPayment;
    this.totalPrice = totalPrice;
  };

  setTotalPrice = (success, totalPrice) => {
    this.hasFetched = success;
    this.hasFailed = !success;
    this.isLoading = false;
    this.totalPrice = totalPrice;
  };

  setRemainingPayment = (success, remainingPayment) => {
    this.hasFetched = success;
    this.hasFailed = !success;
    this.isLoading = false;
    this.remainingPayment = remainingPayment;
  };

  setDownPayment = (success, downPayment) => {
    this.hasFetched = success;
    this.hasFailed = !success;
    this.isLoading = false;
    this.downPayment = downPayment;
  };

  setIsEligibility = (success, isEligibility) => {
    this.hasFetched = success;
    this.hasFailed = !success;
    this.isLoading = false;
    this.isEligibility = isEligibility;
  };

  setHasMechanicalWarranty = (success, hasMechanicalWarranty) => {
    this.hasFetched = success;
    this.hasFailed = !success;
    this.isLoading = false;
    this.hasMechanicalWarranty = hasMechanicalWarranty;
  };

  setVehicleTypes = (success, vehicleTypes) => {
    this.hasFetched = success;
    this.hasFailed = !success;
    this.isLoading = false;
    this.vehicleTypes = vehicleTypes;
  };

  setVehicleType = (success, vehicleType) => {
    this.hasFetched = success;
    this.hasFailed = !success;
    this.isLoading = false;
    this.vehicleType = vehicleType;
  };

  setMake = (success, make) => {
    this.hasFetched = success;
    this.hasFailed = !success;
    this.isLoading = false;
    this.make = make;
  };

  setYears = (success, years) => {
    this.hasFetched = success;
    this.hasFailed = !success;
    this.isLoading = false;
    this.years = years;
  };

  setTrim = (success, trim) => {
    this.hasFetched = success;
    this.hasFailed = !success;
    this.isLoading = false;
    this.trim = trim;
  };

  setColors = (success, colors) => {
    this.hasFetched = success;
    this.hasFailed = !success;
    this.isLoading = false;
    this.colors = colors;
  };
  setStates = (success, states) => {
    this.hasFetched = success;
    this.hasFailed = !success;
    this.isLoading = false;
    this.states = states;
  };

  setCitiesByState = (success, cities) => {
    this.hasFetched = success;
    this.hasFailed = !success;
    this.isLoading = false;
    this.cities = cities;
  };
  setlocationByzipCode = (success, locationByzipCode) => {
    this.hasFetched = success;
    this.hasFailed = !success;
    this.isLoading = false;
    this.locationByzipCode = locationByzipCode;
  };

  setTransmissions = (success, transmissions) => {
    this.hasFetched = success;
    this.hasFailed = !success;
    this.isLoading = false;
    this.transmissions = transmissions;
  };

  setFuels = (success, fuels) => {
    this.hasFetched = success;
    this.hasFailed = !success;
    this.isLoading = false;
    this.fuels = fuels;
  };

  setFeatures = (success, features) => {
    this.hasFetched = success;
    this.hasFailed = !success;
    this.isLoading = false;
    this.features = features;
  };
  setVehicleConditional = (success, vehicleConditionals) => {
    this.hasFetched = success;
    this.hasFailed = !success;
    this.isLoading = false;
    this.vehicleConditionals = vehicleConditionals;
  };

  setDataByPlate = (success, data) => {
    this.hasFetched = success;
    this.hasFailed = !success;
    this.isLoading = false;
    this.dataByPlate = data;
  };

  setValuePriceByFipe = (success, data) => {
    this.hasFetched = success;
    this.hasFailed = !success;
    this.isLoading = false;
    this.valuePriceByFipe = data;
  };

  formatToSelect = array => {
    const select = [defaultSelectObject];
    if (array) {
      select.push(
        ...(array || []).map(element => {
          const { id, name, ...remaining } = element;
          return { value: id, label: name, ...remaining };
        })
      );
    }
    return select;
  };

  formatToSelectWithNameAsAValue = array => {
    const select = [];
    if (array) {
      select.push(
        ...(array || []).map(element => {
          const { name, ...remaining } = element;
          return { value: name, ...remaining };
        })
      );
    }
    return select;
  };

  formatYearsToSelect = array => {
    const select = [defaultSelectObject];
    if (array) {
      select.push(
        ...(array || []).map(element => {
          return { value: Number(element), label: Number(element) };
        })
      );
      select.push({
        value: Number(array[array.length - 1] - 1),
        label: Number(array[array.length - 1] - 1),
      });
    }
    return select;
  };

  formatToCheckBoxList = array => {
    return (array || []).map(element => {
      const { id, name, ...remaining } = element;
      return { value: id, name: name, ...remaining };
    });
  };

  getVehicleTypes = async (vehicletype, inProduction = false) => {
    if (this.vehicletypes) return;
    const { data, success } = await SearchService.getVehicleTypes(
      vehicletype,
      inProduction
    );
    if (success) {
      this.setVehicleType(true, this.formatToSelect(data));
    } else {
      this.setVehicleType(false, null);
    }
  };

  getVehicleTypesBase = async () => {
    const { data, success } = await SearchService.getVehicleTypesBase();
    if (success) {
      this.setVehicleTypes(true, this.formatToSelectWithNameAsAValue(data));
    }
  };

  getMakeByModel = async (vehicleType, make, inProduction = false) => {
    const { data, success } = await SearchService.getMakeByModel(
      vehicleType,
      make,
      inProduction
    );
    if (success) {
      this.setMake(true, this.formatToSelect(data));
    } else {
      this.setMake(false, null);
    }
  };

  getYearsByModel = async (vehicleType, model, inProduction = false) => {
    const { data, success } = await SearchService.getYearsByModel(
      vehicleType,
      model,
      inProduction
    );

    if (success) {
      const hasYearsProduction = data || ((data || {}).years || []).length > 0;
      if (hasYearsProduction) {
        const productionYears = this.formatYearsToSelect(
          ((data || {}).years || []).reverse()
        );
        this.setYears(true, productionYears);
      } else {
        this.setYears(false, null);
      }
    } else {
      this.setYears(false, null);
    }
  };

  getColors = async () => {
    if (this.colors.length > 1) return;

    const { data, success } = await SearchService.getColors();
    if (success) {
      this.setColors(true, this.formatToSelect(data));
    } else {
      this.setColors(false, null);
    }
  };

  getTransmissions = async () => {
    if (this?.transmissions?.length > 1) return;

    const { data, success } = await SearchService.getTransmissions();

    if (success) {
      this.setTransmissions(true, this.formatToSelect(data));
    } else {
      this.setTransmissions(false, null);
    }
  };

  getFuels = async () => {
    if (this.fuels.length > 1) return;
    const { data, success } = await SearchService.getFuels();

    if (success) {
      this.setFuels(true, this.formatToSelect(data));
    } else {
      this.setFuels(false, null);
    }
  };

  getTrimByModelandYear = async (
    vehicleType,
    model,
    year,
    inProduction = false
  ) => {
    const { data, success } = await SearchService.getTrimByModelandYear(
      vehicleType,
      model,
      year,
      inProduction
    );
    if (success) {
      this.setTrim(true, this.formatToSelect(data));
    } else {
      this.setTrim(false, null);
    }
  };
  getEngineByModelandYear = async (
    vehicleType,
    model,
    year,
    inProduction = false
  ) => {
    const { data, success } = await SearchService.getEngineByModelandYear(
      vehicleType,
      model,
      year
    );
    if (success) {
      return { success: true, engine: data };
    } else {
      return { success: false, engine: null };
    }
  };

  getFeatures = async vehicleType => {
    const { data, success } = await SearchService.getFeatures(vehicleType);
    if (success) {
      this.setFeatures(true, this.formatToCheckBoxList(data));
    } else {
      this.setFeatures(false, null);
    }
  };

  getVehicleConditional = async () => {
    if (this.vehicleConditionals.length > 1) return;

    const { data, success } = await SearchService.getVehicleConditional();
    if (success) {
      this.setVehicleConditional(true, this.formatToCheckBoxList(data));
    } else {
      this.setVehicleConditional(false, null);
    }
  };

  getStates = async () => {
    if (this.states.length > 1) return;

    const { data, success } = await SearchService.getStates();
    if (success) {
      this.setStates(true, this.formatToSelect(data));
    } else {
      this.setStates(false, null);
    }
  };

  getCitiesByState = async state => {
    const { data, success } = await SearchService.getCitiesByState(state);
    if (success) {
      this.setCitiesByState(true, this.formatToSelect(data));
      return this.formatToSelect(data);
    } else {
      this.setCitiesByState(false, null);
      return null;
    }
  };
  getLocationByZipcode = async zipcode => {
    const { data, success } = await SearchService.getLocationByZipcode(zipcode);
    if (success) {
      this.setlocationByzipCode(true, data);
    } else {
      this.setlocationByzipCode(false, null);
    }
  };
  getInfoByPlate = async plate => {
    const { data, success } = await InventoryService.getVehicleByPlate(
      this.rootStore.dealerStore.dealer.id,
      plate
    );
    if (success) {
      if (!data) {
        this.setDataByPlate(true, {});
        return { data: data, success: false };
      }
      this.setDataByPlate(true, data);
      return { data: data, success: success };
    } else {
      this.setDataByPlate(false, null);
      return { data: data, success: false };
    }
  };

  getInfoEligibility = async (molicarId, plate, modelYear) => {
    const {
      data,
      success,
    } = await MechanicalWarrantyServices.getEligibilityMechanicalWarranty(
      this.rootStore.dealerStore.dealer.id,
      molicarId,
      plate,
      modelYear
    );
    if (success) {
      if (!data) {
        this.setIsEligibility(true, 0);
        this.setDownPayment(true, 0);
        this.setRemainingPayment(true, 0);
        this.setTotalPrice(true, 0);

        return { data: data, success: false };
      }
      this.setIsEligibility(true, data?.isEligible);
      this.setDownPayment(true, data?.downPayment);
      this.setRemainingPayment(true, data?.remainingPayment);
      this.setTotalPrice(true, data?.totalPrice);

      return { data: data, success: success };
    } else {
      this.setIsEligibility(false, null);
      this.setDownPayment(true, null);
      this.setRemainingPayment(true, null);
      this.setTotalPrice(true, null);

      return { data: data, success: false };
    }
  };

  getHasMechanicalWarranty = async dealId => {
    const {
      data,
      success,
    } = await MechanicalWarrantyServices.getHasMechanicalWarranty(
      this.rootStore.dealerStore.dealer.id,
      dealId
    );
    if (success) {
      if (!data) {
        this.setHasMechanicalWarranty(true, {});
        return { data: data, success: false };
      }
      this.setHasMechanicalWarranty(true, data?.hasMechanicalWarranty);
      return { data: data, success: success };
    } else {
      this.setHasMechanicalWarranty(false, null);
      return { data: data, success: false };
    }
  };

  getValuePriceByFipe = async (trimId, modelYear, deal0km) => {
    this.setValuePriceByFipe(false, null);
    const { data, success } = await SearchService.getValuePriceByFipe(
      trimId,
      modelYear,
      deal0km
    );
    if (success) {
      this.setValuePriceByFipe(true, data);
    } else {
      this.setValuePriceByFipe(false, null);
    }
  };
}
export default remotedev(
  decorate(SearchStore, {
    vehicleType: observable,
    make: observable,
    makeByModel: observable,
    engine: observable,
    years: observable,
    colors: observable,
    porta: observable,
    transmissions: observable,
    vehicleConditionals: observable,
    fuels: observable,
    isEligibility: observable,
    hasMechanicalWarranty: observable,
    hasFetched: observable,
    hasFailed: observable,
    isLoading: observable,
    setInitialState: action,
    setIsEligibility: action,
    formatToSelect: action,
    setMake: action,
    setYears: action,
    setTrim: action,
    setColors: action,
    setTransmissions: action,
    setFuels: action,
    getColors: action,
    getTransmissions: action,
    getFuels: action,
    getFeatures: action,
    setFeatures: action,
    getVehicleConditional: action,
    setVehicleConditional: action,
    setVehicleType: action,
    getVehicleTypes: action,
    getMakeByModel: action,
    getMakeByModelandYear: action,
    getEngineByModelandYear: action,
    getYearsByModel: action,
    getCitiesByState: action,
    setCitiesByState: action,
    setStates: action,
    getStates: action,
    getLocationByZipcode: action,
    setlocationByzipCode: action,
    locationByzipCode: observable,
    states: observable,
    cities: observable,
    setDataByPlate: action,
    getInfoByPlate: action,
    dataByPlate: observable,
    getValuePriceByFipe: action,
    setValuePriceByFipe: action,
    valuePriceByFipe: observable,
    getVehicleTypesBase: action,
    setVehicleTypes: action,
    vehicleTypes: observable,
    getInfoEligibility: action,
    setHasMechanicalWarranty: action,
    getHasMechanicalWarranty: action,
    setDownPayment: action,
    remainingPayment: observable,
    setRemainingPayment: action,
    setTotalPrice: action,
    totalPrice: observable,
  })
);
