import styled, { css } from 'styled-components';

import {
  defaultShadow,
  getSize,
  HORIZONTAL_ALIGMENT,
  SELF_ALIGNMENT,
  VERTICAL_ALIGMENT,
} from '../../styles';
import { Props } from './Wrapper.types';

export const ContainerStyle = css<Props>`
${({ flex }) => (flex ? `display: flex;` : '')}
${({ wrap }) => (wrap ? `flex-wrap: ${wrap};` : '')}
${({ fontWeight }) => (fontWeight ? `font-weight: ${fontWeight};` : '')}
${({ direction }) => (direction ? `flex-direction: ${direction};` : '')}
${({ gap }) => (gap ? `gap: ${getSize(gap)};` : '')}
  ${({ backgroundColor }) =>
    backgroundColor ? `background-color: ${backgroundColor}` : ''};
  align-items: ${({ align = 'stretch' }) => VERTICAL_ALIGMENT[align]};
  align-self: ${({ alignSelf = 'start', expand }) =>
    SELF_ALIGNMENT[expand ? 'stretch' : alignSelf]};
  justify-content: ${({ justify = 'start' }) => HORIZONTAL_ALIGMENT[justify]};
  margin-top: ${({ mt = 'none' }) => getSize(mt)};
  margin-left: ${({ ml = 'none' }) => getSize(ml)};
  margin-bottom: ${({ mb = 'none' }) => getSize(mb)};
  margin-right: ${({ mr = 'none' }) => getSize(mr)};
  ${({ mx }) =>
    mx ? `margin-left: ${getSize(mx)}; margin-right: ${getSize(mx)};` : ''}
  ${({ my }) =>
    my ? `margin-top: ${getSize(my)}; margin-bottom: ${getSize(my)};` : ''}
${({ m }) => (m ? `margin: ${getSize(m)};` : '')}
padding-top: ${({ pt = 'none' }) => getSize(pt)};
  padding-left: ${({ pl = 'none' }) => getSize(pl)};
  padding-bottom: ${({ pb = 'none' }) => getSize(pb)};
  padding-right: ${({ pr = 'none' }) => getSize(pr)};
  ${({ px }) =>
    px ? `padding-left: ${getSize(px)}; padding-right: ${getSize(px)};` : ''}
  ${({ py }) =>
    py ? `padding-top: ${getSize(py)}; padding-bottom: ${getSize(py)};` : ''}
${({ p }) => (p ? `padding: ${getSize(p)};` : '')}
${({ rbl }) => (rbl ? `border-bottom-left-radius: ${getSize(rbl)}` : '')}
${({ rbr }) => (rbr ? `border-bottom-right-radius: ${getSize(rbr)}` : '')}
${({ rtl }) => (rtl ? `border-top-left-radius: ${getSize(rtl)}` : '')}
${({ rtr }) => (rtr ? `border-top-right-radius: ${getSize(rtr)}` : '')}
${({ rt }) =>
  rt
    ? `border-top-left-radius: ${getSize(
        rt
      )}; border-top-right-radius: ${getSize(rt)}`
    : ''}
${({ rb }) =>
  rb
    ? `border-bottom-left-radius: ${getSize(
        rb
      )}; border-bottom-right-radius: ${getSize(rb)}`
    : ''}
${({ rl }) =>
  rl
    ? `border-bottom-left-radius: ${getSize(
        rl
      )}; border-top-left-radius: ${getSize(rl)}`
    : ''}
${({ rr }) =>
  rr
    ? `border-bottom-right-radius: ${getSize(
        rr
      )}; border-top-right-radius: ${getSize(rr)}`
    : ''}
${({ r }) => (r ? `border-radius: ${getSize(r)}` : '')}
${({ borderSize }) => (borderSize ? `border-width: ${borderSize}px` : '')}
${({ borderColor }) => (borderColor ? `border-color: ${borderColor}` : '')}
${({ borderStyle }) => (borderStyle ? `border-style: ${borderStyle}` : '')}
${({ shadow }) => (shadow ? `${defaultShadow}` : '')}
${({ width }) =>
  width ? `width: ${typeof width === 'string' ? width : `${width}px`}` : ''}
  ${({ height }) =>
    height
      ? `height: ${typeof height === 'string' ? height : `${height}px`}`
      : ''}
 ${({ position }) => (position ? `position: ${position}` : '')}
 ${({ zIndex }) => (zIndex ? `zIndex: ${zIndex}` : '')}
 ${({ top }) => (top ? `top: ${top}px` : '')}
 ${({ right }) => (right ? `right: ${right}px` : '')}
 ${({ bottom }) => (bottom ? `bottom: ${bottom}px` : '')}
 ${({ left }) => (left ? `left: ${left}px` : '')}
 ${({ color }) => (color ? `color: ${color};` : '')}
 ${({ opacity }) => (opacity ? `opacity: ${opacity}%` : '')}
 ${({ fontSize }) => (fontSize ? `font-size: ${fontSize}px` : '')}
 ${({ boxSizing }) => (boxSizing ? `box-sizing: ${boxSizing}` : '')}
 ${({ lineHeight }) => (lineHeight ? `line-height: ${lineHeight};` : '')}
 ${({ cursor }) => (cursor ? `cursor: ${cursor};` : '')}
`;

export const ContainerWrapper = styled.div<Props>`
  ${ContainerStyle}
`;
