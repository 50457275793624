import { format } from 'date-fns';
import { useChatContext } from 'pages/chats/ChatsContext';
import { useQuery } from 'react-query';
import mobiIAServices from 'services/mobiIAServices';

const useGetLead360 = () => {
  const { currentProposal, interactions }: any = useChatContext();
  const {
    clientUserName,
    clientUserPhone,
    clientEmail,
    vehicle,
    annotations,
    scheduleds,
    dealerId,
    id,
  } = currentProposal;

  const interactionsList = interactions?.length
    ? interactions?.map(interaction => ({
        date: interaction?.date
          ? format(new Date(interaction?.date), 'dd/MM/yyyy')
          : '',
        fromPJ: interaction?.fromPJ,
        ownerName: interaction?.ownerName ? interaction?.ownerName : '',
        message: interaction?.message ?? '',
      }))
    : [];

  const interestVehicles = vehicle ? vehicle?.map(item => item?.title) : [];

  const annotationsList = annotations
    ? annotations.map(item => ({
        date: item?.date ? format(new Date(item?.date), 'dd/MM/yyyy') : '',
        message: item?.message ? item?.message : '',
      }))
    : [];

  const scheduledList = scheduleds
    ? scheduleds.map(item => ({
        date: item?.date ? format(new Date(item?.date), 'dd/MM/yyyy') : '',
        type: item?.type?.name ? item?.type?.name : '',
        done: item?.visited,
      }))
    : [];

  const body = {
    prompt: {
      conversationHistory: [...interactionsList],
      annotations: [...annotationsList],
      schedules: [...scheduledList],
      vehicles: [...interestVehicles],
      client: {
        name: clientUserName,
        phone: clientUserPhone,
        email: clientEmail,
      },
    },
    id: `proposal-${currentProposal?.id}`,
  };

  const { data, isLoading, isFetching } = useQuery(
    [id, `get_lead_360_${dealerId}`],

    () => mobiIAServices.getLead360(dealerId, body),
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      staleTime: 0,
    }
  );

  const message = data?.data?.summary;

  return {
    message: message,
    isLoading: isLoading || isFetching,
  };
};

export default useGetLead360;
