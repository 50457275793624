import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ActionCreators from 'modules/resale/store/reducers/callCenter/actionCreators';
import ProposalFiltersComponent from './ProposalFiltersComponentCallCenter';
import manageLocalStorage from 'pages/callCenter/utils/manageLocalStorage';

const ProposalFiltersContainer = props => {
  const dispatch = useDispatch();

  const {
    search,
    origin,
    tags,
    channels,
    filterByDates,
    departmentCode,
    salesAssistantId,
    dealers,
  } = useSelector(state => ({
    search: state.callCenter.filters.query,
    origin: state.callCenter.filters.origin,
    tags: state.callCenter.filters.tags,
    channels: state.callCenter.filters.providerId,
    filterByDates: {
      initialDate: state.callCenter.filters.periodInit,
      finalDate: state.callCenter.filters.periodFinal,
      periodId: state.callCenter.filters.periodId,
    },
    departmentCode: state.callCenter.filters.departmentCode,
    salesAssistantId: state.callCenter.filters.salesAssistantId,
    dealers: state.callCenter.filters.dealerId,
  }));

  const [state, setState] = useState({
    overviewFilter: null,
  });

  const getQueryFilter = () => {
    let queryFilter = props.history?.location?.search
      ? props.history.location.search.split('&')
      : null;

    if (!queryFilter) return null;

    queryFilter.shift();

    if (!queryFilter?.length) return null;

    queryFilter = queryFilter?.[0]?.split('=');

    const filter = {
      filterByNewLeadDelayed: 'Leads Novos Atrasados',
      filterByInactiveLead: 'Leads inativos em negociação',
      filterByScheduledDelayed: 'Agendamentos atrasados',
      filterByCall: 'Total de Ligações',
      filterByLeadWithSold: 'Vendas',
    };

    setState(prevState => ({
      ...prevState,
      overviewFilter: {
        name: filter?.[queryFilter[0]],
        filterName: queryFilter[0],
      },
    }));

    return queryFilter;
  };

  const handleChangeFilter = ({ name, value }) => {
    manageLocalStorage.setFilters(name, value);
    dispatch(ActionCreators.managementFilters({ [name]: value }));
  };

  const handleOnChangePeriodInit = ({ periodId, periodInit, periodFinal }) => {
    manageLocalStorage.setFilters('periodId', periodId);
    dispatch(ActionCreators.managementFilters({ periodInit, periodFinal }));
  };

  const handleOnChangePeriod = newDates => {
    handleOnChangePeriodInit({
      periodId: newDates.periodId,
      periodInit: newDates.initialDate,
      periodFinal: newDates.finalDate,
    });
  };

  const handleChangeDepartment = newValue => {
    handleChangeFilter({ name: 'departmentCode', value: newValue });
  };

  const handleChangeChannel = chanelArrValue => {
    handleChangeFilter({ name: 'providerId', value: chanelArrValue });
  };

  const handleChangeOrigin = originArrValue => {
    handleChangeFilter({ name: 'origin', value: originArrValue });
  };

  const handleChangeTags = tagArrValue => {
    handleChangeFilter({ name: 'tags', value: tagArrValue });
  };

  const handleChangeSalesAssistant = salesAssistant => {
    handleChangeFilter({ name: 'salesAssistantId', value: salesAssistant });
  };

  const handleChangeDealers = dealers => {
    handleChangeFilter({ name: 'dealerId', value: dealers });
  };

  const handleChangeSearch = event => {
    const { value } = event.target;
    handleChangeFilter({ name: 'query', value });
  };

  const handleOnClickToSearch = () => {
    props.getProposals(search);
  };

  const handleClickGetNewProposals = () => {
    props.getProposals();
  };

  const handleClearAllFilters = () => {
    props.getProposals(null, false, true);
  };

  useEffect(() => {
    getQueryFilter();
  }, []);

  const viewComponentProps = {
    channels,
    departmentCode,
    handleChangeChannel,
    handleChangeDepartment,
    handleChangeSearch,
    handleChangeOrigin,
    handleChangeSalesAssistant,
    handleChangeTags,
    handleClickGetNewProposals,
    handleOnChangePeriod,
    handleOnClickToSearch,
    handleClearAllFilters,
    handleChangeDealers,
    hasNewProposals: props.hasNewProposals,
    open: state.open,
    origin: origin,
    overviewFilter: state.overviewFilter,
    periodFinal: filterByDates.finalDate,
    periodInit: filterByDates.initialDate,
    search,
    salesAssistantId,
    setSomeUnreadMessages: props.setSomeUnreadMessages,
    someUnreadMessages: props.someUnreadMessages,
    tags,
    tasks: props.tasks,
    dealers,
    isCallCenter: true,
    periodId: filterByDates.periodId,
    isSimple: props?.isSimple,
    otherElements: props?.otherElements,
    statusKey: props?.statusKey,
  };

  return <ProposalFiltersComponent {...viewComponentProps} />;
};

export default React.memo(ProposalFiltersContainer);
