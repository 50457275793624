import React from 'react';
import { useSelector } from 'react-redux';
import Tooltip from 'bit/mobiauto.web-ui-components.components.tooltip';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';
import { highAccessUserTypes } from 'constants/userAccess';
import {
  Container,
  TagColor,
  CustomCheckbox,
  TagBackground,
  ContainerIcon,
} from './styles';

export interface CheckedTagsProps {
  name: string;
  id?: number;
  color: string;
}
export type TagChipProps = {
  label?: string;
  color?: string;
  onClickCancel?: () => void;
  tagId: number;
  checkedTags?: CheckedTagsProps[];
  onClick?: (tag: CheckedTagsProps) => void;
  tagOwnerId?: number | string;
  deleteTag?: (tagId: number) => void;
  editTag?: () => void;
  disabledCheck?: boolean;
};

const TagChip = ({
  label = '',
  color = '#ddd',
  onClickCancel,
  tagId,
  checkedTags,
  onClick,
  tagOwnerId,
  deleteTag,
  editTag,
  disabledCheck = false,
}: TagChipProps) => {
  const { userType, userSite } = useSelector(state => state?.userStore);

  const tagHasChecked = !!checkedTags?.find(
    tag => Number(tag.id) === Number(tagId)
  );

  const currentTag = {
    name: label,
    id: tagId,
    color: color,
    tagOwnerId: tagOwnerId,
  };

  const hasInteraction = typeof onClick === 'function';

  const currentUserNotIsOwner =
    !highAccessUserTypes?.includes(userType) &&
    currentTag?.tagOwnerId != userSite?.id;

  return (
    <Tooltip title={currentTag.name}>
      <Container
        hasChecked={tagHasChecked}
        onClick={disabledCheck ? () => null : () => onClick(currentTag)}
        hasInteraction={hasInteraction}
      >
        <TagBackground
          bgColor={currentTag.color}
          hasChecked={tagHasChecked}
          hasInteraction={hasInteraction}
        />
        <Box display="flex" alignItems="center" style={{ gap: '8px' }}>
          {checkedTags ? (
            <CustomCheckbox
              checked={tagHasChecked}
              style={{
                color: `${currentTag.color}`,
                background: checkedTags?.find(tag => tag.id === tagId)
                  ? 'white'
                  : currentTag.color,
                zIndex: 1,
              }}
            />
          ) : (
            <TagColor bgColor={currentTag.color} />
          )}
          <Box
            marginRight="9px"
            zIndex="1"
            whiteSpace="nowrap"
            maxWidth="150px"
            overflow={'hidden'}
            textOverflow={'ellipsis'}
          >
            {currentTag.name}
          </Box>
        </Box>

        <Box component="div" zIndex="1" display="flex" style={{ gap: '8px' }}>
          {!!onClickCancel && (
            <ContainerIcon
              onClick={event => {
                event.stopPropagation();
                onClickCancel();
              }}
            >
              <CloseIcon fontSize="small" />
            </ContainerIcon>
          )}
          {!!editTag && (
            <ContainerIcon
              onClick={event => {
                event.stopPropagation();
                editTag();
              }}
            >
              <EditOutlinedIcon fontSize="small" />
            </ContainerIcon>
          )}
          {!!deleteTag && (
            <Tooltip
              title={
                currentUserNotIsOwner
                  ? 'Esta tag só pode ser removida pelo usuário que a adicionou.'
                  : ''
              }
            >
              <ContainerIcon
                onClick={event => {
                  event.stopPropagation();
                  deleteTag(tagId);
                }}
                disabled={currentUserNotIsOwner}
              >
                <DeleteOutlineIcon fontSize="small" />
              </ContainerIcon>
            </Tooltip>
          )}
        </Box>
      </Container>
    </Tooltip>
  );
};

export default TagChip;
