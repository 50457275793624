import React from 'react';
import { useDropzone } from 'react-dropzone';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import Wrapper from 'components/Wrapper';
import Text from 'modules/mobiOne/components/Text';
import { propTypes } from './DragzoneBoxTypes';

const DragzoneBoxComponent = ({
  fileState,
  setFileState,
  textDragzone = 'Clique ou arraste o arquivo até aqui.',
  heightBoxUpload = 72,
  disabled = false,
  acceptFiles = {
    'image/*': [],
    'application/pdf': [],
    '.ofx': [],
    '.pfx': [],
  },
}: propTypes) => {
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: acceptFiles,
    maxSize: 20000000,
    multiple: false,
    disabled: disabled,
    onDrop: acceptedFiles => {
      setFileState(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const getColor = () => {
    return (
      (isFocused && '#4443BC') ||
      (isDragAccept && '#00e676') ||
      (isDragReject && '#F44336') ||
      '#009A8A'
    );
  };

  const handleRemove = () => {
    setFileState([]);
  };

  return (
    <>
      {fileState?.length > 0 ? (
        <Wrapper
          flex
          justify="between"
          align="center"
          gap="sm"
          height={72}
          style={{ cursor: 'pointer' }}
        >
          <Wrapper
            flex
            align="center"
            gap="sm"
            height={72}
            style={{ cursor: 'pointer' }}
          >
            <InsertDriveFileIcon
              htmlColor="#4443BC"
              fontSize="large"
              cursor="pointer"
            />
            <Text cursor="pointer">{fileState[0]?.name}</Text>
          </Wrapper>
          {!disabled && (
            <DeleteOutlineOutlinedIcon
              htmlColor="#F44336"
              cursor="pointer"
              onClick={handleRemove}
            />
          )}
        </Wrapper>
      ) : (
        <Wrapper
          flex
          justify="center"
          align="center"
          borderStyle="dashed"
          borderSize={1}
          borderColor={getColor()}
          height={heightBoxUpload}
          gap="md"
          {...getRootProps({ className: 'dropzone' })}
        >
          <input {...getInputProps()} />
          <CloudUploadIcon htmlColor={getColor()} />
          <Text>{textDragzone}</Text>
        </Wrapper>
      )}
    </>
  );
};

export default DragzoneBoxComponent;
