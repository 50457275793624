import React from 'react';
import styled from 'styled-components';
import Colors from 'bit/mobiauto.web-ui-components.components.colors/colors';
import Close from '@material-ui/icons/Close';
import withStyles from '@material-ui/core/styles/withStyles';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { LazyImg } from 'components/LazyImg';
import getImage from 'components/getImage';
import { Desktop, Mobile } from 'components/Responsive';

const Line = styled.div`
  text-align: left;
  display: flex;
  flex-direction: row;
`;
const LineSpaceBetween = styled(Line)`
  justify-content: space-between;
  width: 100%;
`;
const TitleText = styled.div`
  font-family: Poppins Bold;
  color: ${Colors.gray.dark};
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.94;
  letter-spacing: normal;
  padding-bottom: 4px;
`;
const SubtitleText = styled.div`
  font-family: Poppins Regular;
  color: ${Colors.gray.dark};
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
`;

const styles = {
  dialogContentRoot: {
    opacity: 0.92,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& img': { maxWidth: '692px' },
  },
  dialogActionsRoot: { display: 'flex', justifyContent: 'space-between' },
  buttonCancel: {
    fontFamily: 'Poppins Regular',
    color: Colors.primary.medium,
    backgroundColor: 'transparent',
    borderRadius: '4px',
    marginRight: '32px',
    border: `solid 1px ${Colors.primary.medium}`,
  },
  buttonConfirm: {},
  iconsMenu: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
};

class PreViewMessageComponent extends React.PureComponent {
  getLinkImage = ({ imageId, imageVcode }, width = 0) => {
    return getImage({ imageId, imageVcode }, 100, width, 0);
  };
  download = linkImage => {
    this.imageRef.download = 'image.png';
    this.imageRef.href = linkImage;
    this.imageRef.src = linkImage;
    // this.imageRef.type =
    this.imageRef.target = '_blank';
    this.imageRef.click();
  };
  render() {
    const {
      message,
      handleOnClose,
      open,
      ownerName,
      date,
      classes,
      imageUrl,
    } = this.props;

    return (
      <React.Fragment>
        <Desktop>
          <Dialog
            open={open}
            onClose={handleOnClose}
            aria-labelledby="simple-dialog-title"
            fullScreen
          >
            <DialogTitle>
              <LineSpaceBetween>
                <Grid item xs={10}>
                  <TitleText>{ownerName}</TitleText>
                  <SubtitleText>{date}</SubtitleText>
                </Grid>
                <Grid item xs={2} classes={{ item: classes.iconsMenu }}>
                  <IconButton aria-label="Close" onClick={handleOnClose}>
                    <Close />
                  </IconButton>
                </Grid>
              </LineSpaceBetween>
            </DialogTitle>
            <DialogContent classes={{ root: classes.dialogContentRoot }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <LazyImg
                  src={
                    imageUrl ??
                    this.getLinkImage({
                      imageId: message?.media?.id,
                      imageVcode: message?.media?.vcode,
                    })
                  }
                  alt="message"
                  id="message image"
                  style={{ height: 'calc(100vh - 190px)', maxWidth: '80%' }}
                  download
                />

                {message?.message && <div>{message.message}</div>}
              </div>
            </DialogContent>
          </Dialog>
        </Desktop>
        <Mobile>
          <Dialog
            open={open}
            onClose={handleOnClose}
            aria-labelledby="simple-dialog-title"
            fullScreen
          >
            <DialogTitle>
              <LineSpaceBetween>
                <Grid item xs={10}>
                  <TitleText>{ownerName}</TitleText>
                  <SubtitleText>{date}</SubtitleText>
                </Grid>
                <Grid item xs={2} classes={{ item: classes.iconsMenu }}>
                  <IconButton aria-label="Close" onClick={handleOnClose}>
                    <Close />
                  </IconButton>
                </Grid>
              </LineSpaceBetween>
            </DialogTitle>
            <DialogContent classes={{ root: classes.dialogContentRoot }}>
              <LazyImg
                src={
                  imageUrl ??
                  this.getLinkImage(
                    {
                      imageId: message?.media?.id,
                      imageVcode: message?.media?.vcode,
                    },
                    0
                  )
                }
                alt="message"
                download
                width="100%"
              />
            </DialogContent>
          </Dialog>
        </Mobile>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(PreViewMessageComponent);
