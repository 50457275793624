import styled, { css } from 'styled-components';
import Colors from 'bit/mobiauto.web-ui-components.components.colors/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
`;

export const ProviderIcon = styled.img`
  width: 25px;
  height: 25px;
  margin: 0 auto;
  align-self: center;
  margin-bottom: 6px;
  background: #fff;
  padding: 2px;
  border-radius: 50%;
`;

export const ContainerIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const FooterMessage = styled.footer`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 7px;
`;

export const OvalBigger = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  width: 32px;
  height: 32px;

  font-family: 'Poppins Bold';
  font-size: 14px;
  color: #333333;

  background-color: ${Colors.gray.nearWhite};

  border-radius: 16px;
`;

export const Box = styled.div<{ isPF: boolean }>`
  max-width: 80%;
  color: #ffffff;
  font-family: 'Poppins Regular';
  font-size: 12px;

  ${props =>
    props.isPF
      ? css`
          border-radius: 8px 8px 8px 0px;
          padding: 8px 16px;
          background-color: #4300d2;
        `
      : css`
          padding: 8px 8px 8px 16px;
          border-radius: 8px 8px 0px 8px;
          background-color: #00b2a9;
        `}
`;

export const ContainerMessage = styled.div<{ isPF: boolean }>`
  display: flex;
  flex-flow: ${props => props.flexDirection || 'wrap-reverse'};
  padding: ${props => props.paddingRight || '0'};
  flex-wrap: nowrap;
  align-items: flex-end;
  justify-content: ${props => (!props.isPF ? 'flex-start' : 'flex-end')};
`;

export const CheckReadContainer = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 0px 0px 0px 8px;
  align-items: center;
  > svg {
    font-size: 16px;
    color: ${({ readMessage }) => (readMessage ? Colors.stars.medium : '#fff')};
  }
`;
